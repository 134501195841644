import {LotStatus} from "../../lotAggregate/LotStatus";
import {ICheckable} from "../../globalComponents/Checkbox/ICheckable";
import {TableTitle} from "../../globalComponents/Table/TableTitle";

export enum SubdivisionLotTableDataProperties {
    LotStatus = "Lot Status",
    CustomerName = "Customer Name",
    TrussManufacturerName = "Truss Manufacturer Name",
    WindExposure = "Wind Exposure",
    WindSpeed = "Wind Speed"
}

export class SubdivisionLotTableData implements ICheckable {
    public readonly id: number;
    public readonly lotName: string;
    public readonly lotStatus: Map<number, LotStatus>;
    public readonly customerName: Map<number, string>;
    public readonly trussManufacturerName: Map<number, string>;
    public readonly windExposure: Map<number, string>;
    public readonly windSpeed: Map<number, string>;
    protected _isChecked: boolean;
    protected _isActionPanelOpen: boolean;
    private _isLoading: boolean;


    constructor(id: number, lotName: string, lotStatus: Map<number, LotStatus>, customerName: Map<number, string>, trussManufacturerName: Map<number, string>, windExposure: Map<number, string>, windSpeed: Map<number, string>, isActionPanelOpen: boolean = false, isLotChecked: boolean = false, isLoading: boolean = false) {
        this.id = id;
        this.lotName = lotName;
        this.lotStatus = lotStatus;
        this.customerName = customerName;
        this.trussManufacturerName = trussManufacturerName;
        this.windExposure = windExposure;
        this.windSpeed = windSpeed;
        this._isActionPanelOpen = isActionPanelOpen;
        this._isChecked = isLotChecked;
        this._isLoading = isLoading;
    }

    public clone(): this {
        return new SubdivisionLotTableData(this.id, this.lotName, this.lotStatus, this.customerName, this.trussManufacturerName, this.windExposure, this.windSpeed, this._isActionPanelOpen, this._isChecked, this._isLoading) as this;
    }

    //region getters
    get isActionPanelOpen(): boolean {
        return this._isActionPanelOpen;
    }

    get isChecked(): boolean {
        return this._isChecked;
    }

    //endregion

    //region state methods

    public toggleActionPanelVisibility(): SubdivisionLotTableData {
        const result = this.clone();
        result._isActionPanelOpen = !result._isActionPanelOpen;
        return result;
    }

    public closeActionPanel() {
        const result = this.clone();
        result._isActionPanelOpen = false;
        return result;
    }

    public toggleIsChecked(): this {
        const result = this.clone();
        result._isChecked = !result._isChecked;
        return result;
    }

    public setIsCheckedToFalse(): SubdivisionLotTableData {
        const result = this.clone();
        result._isChecked = false;
        return result;
    }

    public toggleIsLoading() {
        const result = this.clone();
        result._isLoading = !result._isLoading;
        return result;
    }

    public getArrangeablePropertiesByColumnId(tableTitle: TableTitle) {
        switch(tableTitle.displayValue) {
            case SubdivisionLotTableDataProperties.LotStatus:
                return this.getLotStatusByColumnId(tableTitle.id);
            case SubdivisionLotTableDataProperties.CustomerName:
                return this.getCustomerNameByColumnId(tableTitle.id);
            case SubdivisionLotTableDataProperties.TrussManufacturerName:
                return this.getTrussManufacturerNameByColumnId(tableTitle.id);
            case SubdivisionLotTableDataProperties.WindExposure:
                return this.getWindExposureByColumnId(tableTitle.id);
            case SubdivisionLotTableDataProperties.WindSpeed:
                return this.getWindSpeedByColumnId(tableTitle.id);
            default:
                return "N/A";
        }
    }

    private getLotStatusByColumnId(columnId: number) {
        return this.lotStatus.get(columnId);
    }

    private getCustomerNameByColumnId(columnId: number) {
        return this.customerName.get(columnId);
    }
    
    private getTrussManufacturerNameByColumnId(columnId: number) {
        return this.trussManufacturerName.get(columnId);
    }
    
    private getWindExposureByColumnId(columnId: number) {
        return this.windExposure.get(columnId);
    }
    
    private getWindSpeedByColumnId(columnId: number) {
        return this.windSpeed.get(columnId);
    }

    //endregion
}