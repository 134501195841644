import {PricingMatrixUnitType} from "./PricingMatrixUnitType";
import {InputGroupTypes} from "../../globalComponents/InputGroupTypes";
import {PricingMatrixBillingTypeDropdownOptions} from "../UI/PricingMatrixBillingTypeDropdownOptions";
import {PricingMatrixDiscountDropdownOptions} from "../UI/PricingMatrixDiscountDropdownOptions";
import {IPricingMatrixField} from "./IPricingMatrixField";

export class PricingMatrixField implements IPricingMatrixField {
    public value: string;
    public unitType: PricingMatrixUnitType;
    public label: string;
    public inputGroupType: InputGroupTypes;
    public dropdownOptions: typeof PricingMatrixBillingTypeDropdownOptions | typeof PricingMatrixDiscountDropdownOptions;
    public name: string;


    constructor(value: string, unitType: PricingMatrixUnitType, label: string, inputGroupType: InputGroupTypes, dropdownOptions: typeof PricingMatrixBillingTypeDropdownOptions | typeof PricingMatrixDiscountDropdownOptions, name: string) {
        this.value = value;
        this.unitType = unitType;
        this.label = label;
        this.inputGroupType = inputGroupType;
        this.dropdownOptions = dropdownOptions;
        this.name = name;
    }
}