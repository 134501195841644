import {IAPIGateway} from "../../infrastructure/IAPIGateway";
import {IOrderEntryBatchGateway} from "./IOrderEntryBatchGateway";
import {OrderEntryEmailDetailViewModel} from "../viewModels/OrderEntryEmailDetailViewModel";
import {OrderEntryEditViewModel} from "../viewModels/OrderEntryEditViewModel";
import {OrderEntryDetailViewModel} from "../viewModels/OrderEntryDetailViewModel";
import {OrderEntryAssociationViewModel} from "../viewModels/OrderEntryAssociationViewModel";

export class OrderEntryBatchGateway implements IOrderEntryBatchGateway {
    private readonly _baseUrl: string = "/api/orderEntryBatch";
    private readonly _apiGateway: IAPIGateway;
    
    constructor(apiGateway: IAPIGateway) {
        this._apiGateway = apiGateway;
    }
    
    public async initializeNewSsReissue(files: File[]): Promise<string> {
        return await this._apiGateway.postFiles(`${this._baseUrl}/uploadEmail`, files)
    }
    public async addNewOrderEntry(orderEntryBatchId:string){
        return await this._apiGateway.put(this._baseUrl + `/${orderEntryBatchId}/orderEntries`, {});
    }
    public async updateOrderEntry(orderEntryBatchId:string, orderEntryId:string, orderEntryEditVm: OrderEntryEditViewModel ){
        return await this._apiGateway.put(this._baseUrl + `/${orderEntryBatchId}/orderEntries/${orderEntryId}`, orderEntryEditVm);
    }
    public async  removeOrderEntryFromOrderEntryBatch(orderEntryBatchId: string, orderEntryId: string){
        return await this._apiGateway.delete(this._baseUrl + `/${orderEntryBatchId}/orderEntries/${orderEntryId}`);
    }
    
    public async listOrderEntriesByBatchId(batchId:string): Promise<OrderEntryDetailViewModel[]> {
        return await this._apiGateway.get(`${this._baseUrl}/${batchId}/orderEntries`)
    }
    
    public async listOrderEntryEmailsByBatchId(orderEntryBatchId: string): Promise<OrderEntryEmailDetailViewModel[]> {
        return await this._apiGateway.get(`${this._baseUrl}/${orderEntryBatchId}/orderEntryEmailDetails`)
    }
    
    public async associateOrderEntryEmailWithAllOrderEntriesInOrderEntryBatch(orderEntryBatchId: string, orderEntryEmailId: string) {
        return await this._apiGateway.put(this._baseUrl + `/${orderEntryBatchId}/orderEntryEmails/${orderEntryEmailId}/associateAllOrderEntries`, {});
    }
    public async removeOrderEntryEmailFromOrderEntryBatch(orderEntryBatchId:string, orderEntryEmailId:string) {
        return await this._apiGateway.delete(this._baseUrl + `/${orderEntryBatchId}/orderEntryEmails/${orderEntryEmailId}`);
    }
    public async addEmailFilesToOrderEntryBatch(orderEntryBatchId: string, emailFiles: File[]): Promise<void> {
        return await this._apiGateway.postFiles(`${this._baseUrl}/${orderEntryBatchId}/orderEntryEmails/uploadEmails`, emailFiles);
    }
    
    public async addAndDuplicateOrderEntry(orderEntryBatchId: string): Promise<void> {
        return await this._apiGateway.put(`${this._baseUrl}/${orderEntryBatchId}/orderEntries/addAndDuplicate`, {});
    }
    
    public async associateOrderEntryEmailWithOrderEntries(orderEntryBatchId: string, orderEntryAssociationViewModel: OrderEntryAssociationViewModel): Promise<void> {
        return await this._apiGateway.put(`${this._baseUrl}/${orderEntryBatchId}/orderEntryEmails/${orderEntryAssociationViewModel.orderEntryEmailId}/associateWithOrderEntries`, orderEntryAssociationViewModel);
    }

    public async unAssociateOrderEntryEmailWithAllOrderEntriesInOrderEntryBatch(orderEntryBatchId: string, orderEntryEmailId: string): Promise<void> {
        return await this._apiGateway.put(`${this._baseUrl}/${orderEntryBatchId}/orderEntryEmails/${orderEntryEmailId}/unAssociateAll`)
    }


    public async unAssociateOrderEntryWithOrderEntryEmail(orderEntryBatchId: string, orderEntryEmailId: string, orderEntryId: string): Promise<void> {
        return await this._apiGateway.put(`${this._baseUrl}/${orderEntryBatchId}/orderEntryEmail/${orderEntryEmailId}/orderEntry/${orderEntryId}/unAssociate`);
    }

    public async addAndDuplicateOrderEntryWithCustomer(orderEntryBatchId: string): Promise<void> {
        return await this._apiGateway.put(`${this._baseUrl}/${orderEntryBatchId}/orderEntries/addAndDuplicateWithCustomer`, {});
    }
    
    public async submitOrderEntryBatch(orderEntryBatchId: string) {
        return await this._apiGateway.put(`${this._baseUrl}/${orderEntryBatchId}/submit`);
    }
}