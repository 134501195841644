import {createContext, Dispatch, SetStateAction} from "react";
import {OmniSearchDomainObject} from "../domain/OmniSearchDomainObject";
import {OmniSearchValueObject} from "../valueObjects/OmniSearchValueObject";

interface IOmniSearchContextProps {
    omniSearchDomainObject: OmniSearchDomainObject;
    options: OmniSearchValueObject[] | undefined;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    listInnerRef: any;
    isLoaded: boolean;
    initialState: OmniSearchValueObject[];
    selectedCategory: string;
    query: string;
    setQuery: Dispatch<SetStateAction<string>>;
    setSelectedCategory: Dispatch<SetStateAction<string>>;
}

export const OmniSearchContext = createContext<IOmniSearchContextProps>({
    omniSearchDomainObject: new OmniSearchDomainObject({ current: undefined }, false, [{ category: "", options: [] }], [{ category: "", options: [] }], "", ""),
    options: undefined,
    listInnerRef: undefined,
    isLoaded: false,
    initialState: [{ category: "", options: [] }],
    selectedCategory: "",
    query: "",
    setQuery: () => {},
    setSelectedCategory: () => {},
})