import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {OmniSearchTypeaheadComponent} from "./OmniSearchTypeaheadComponent";
import {useContext} from "react";
import {OmniSearchContext} from "../state/OmniSearchContext";

export const OmniSearchComponent = () => {
    const omniSearchContext = useContext(OmniSearchContext);
    const {options, selectedCategory, isLoaded, query, listInnerRef, setQuery, setSelectedCategory} = omniSearchContext;
    return (
        <>
            <OmniSearchTypeaheadComponent
                listInnerRef={listInnerRef}
                options={options ?? []}
                icon={<MagnifyingGlassIcon className={"w-5 h-5 text-gray-500"}/>}
                selectedCategory={selectedCategory}
                isLoaded={isLoaded}
                disabled={false} dataTestId={"omniSearch"}
                placeholderText={'Search for Customer, Plan, Subdivision, or Lot'}
                query={query} 
                setQuery={setQuery}
                setSelectedCategory={setSelectedCategory}
            />
        </>
    )
}