import {IRequirementSetGateway} from "./IRequirementSetGateway";
import {RequirementSetEditViewModel} from "../viewModels/RequirementSetEditViewModel";
import {IAPIGateway} from "../../infrastructure/IAPIGateway";
import {ProjectTeamViewModel} from "../../projectTeamAggregate/viewModels/ProjectTeamViewModel";
import {PricingMatrixEditViewModel} from "../../pricingMatrixAggregate/ViewModels/PricingMatrixEditViewModel";

export default class RequirementSetGateway implements IRequirementSetGateway {
    baseUrl: string = '/api/requirementSets';
    private readonly _apiGateway: IAPIGateway;

    constructor(apiService: IAPIGateway) {
        this._apiGateway = apiService;
    }

    public updateRequirementSet = async (id: number, data: RequirementSetEditViewModel, signal: AbortSignal) => {
        return await this._apiGateway.put(`${this.baseUrl}/${id}`, data, signal);
    }

    public getRequirementSetByCustomerId = async (customerId: number): Promise<RequirementSetEditViewModel> => {
        return await this._apiGateway.get(`${this.baseUrl}/customer/${customerId}`);
    }

    public getRequirementSetByPlanId = async (planId: number): Promise<RequirementSetEditViewModel> => {
        return await this._apiGateway.get(`${this.baseUrl}/plan/${planId}`);
    }

    public async getRequirementSetProjectTeamByProjectTeamId(projectTeamId: number): Promise<ProjectTeamViewModel> {
        return await this._apiGateway.get(`${this.baseUrl}/projectTeam/${projectTeamId}`);
    }

    public async getRequirementSetPricingMatrixByPricingMatrixId(pricingMatrixId: number): Promise<PricingMatrixEditViewModel> {
        return await this._apiGateway.get(`${this.baseUrl}/pricingMatrix/${pricingMatrixId}`);
    }

}