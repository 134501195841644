import {DisciplinePhase} from "../../disciplinePhaseAggregate/domain/DisciplinePhase";
import {PricingMatrixFactory} from "../../pricingMatrixAggregate/Factories/PricingMatrixFactory";

export class Discipline {
    private _id: number;
    private _name: string;
    private _disciplinePhases: Array<DisciplinePhase>;
    private _isSelected: boolean;

    constructor(id: number, name: string, disciplinePhases: Array<DisciplinePhase>, isSelected: boolean) {
        this._id = id;
        this._name = name;
        this._disciplinePhases = disciplinePhases;
        this._isSelected = isSelected;
    }
    
    private clone() {
        return new Discipline(this._id, this._name, this._disciplinePhases, this._isSelected);
    }

    public addDisciplinePhase = (disciplinePhase: DisciplinePhase) => {
        const pricingMatrixFactory = new PricingMatrixFactory();
        disciplinePhase.updateDisciplinePhasePricingMatrix(pricingMatrixFactory.createEmptyPricingMatrix(disciplinePhase.id));
        this._disciplinePhases.push(disciplinePhase);
        return new Discipline(this._id, this._name, this._disciplinePhases, this._isSelected);
    }
    
    public removeAllDisciplinePhases() {
        this._disciplinePhases = [];
        return new Discipline(this._id, this._name, this._disciplinePhases, this._isSelected);
    }
    
    public removeDisciplinePhase = (disciplinePhase: DisciplinePhase) => {
        const index = this._disciplinePhases.findIndex(x => x.id === disciplinePhase.id);
        this._disciplinePhases.splice(index, 1);
        return new Discipline(this._id, this._name, this._disciplinePhases, this._isSelected);
    }
    
    public setIsSelected(isSelected: boolean) {
        const result = this.clone();
        result._isSelected = isSelected;
        return result;
    }
    
    public getSelectedDisciplinePhases() {
        return this._disciplinePhases.filter(x => x.isSelected);
    }


    get id(): number {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get disciplinePhases(): Array<DisciplinePhase> {
        return this._disciplinePhases;
    }

    get isSelected(): boolean {
        return this._isSelected;
    }
}