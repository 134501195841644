import React, {PropsWithChildren, useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {CurrentUserViewModel} from "../userAggregate/viewModels/CurrentUserViewModel";
import {UserDetailViewModel} from "../userAggregate/viewModels/UserDetailViewModel";
import {LoginURLs} from "../routes/LoginURLs";
import {ServiceContext} from "./ServiceContextProvider";

type AuthContext = {
    isLoaded: boolean,
    user: CurrentUserViewModel,
    userDetail: UserDetailViewModel,
    fullName: string,
}

//This creates our user Context which is populated from the callback of getUserInfo.
export const UserContext = React.createContext<AuthContext>({
    isLoaded: false,
    user: {} as CurrentUserViewModel,
    userDetail: {} as UserDetailViewModel,
    fullName: ""
});

export const UserContextProvider = ({children}: PropsWithChildren) => {
    const {UserGateway} = useContext(ServiceContext);
    const loginPage = window.location.href.indexOf("login") > -1 || window.location.pathname === "/";
    const navigate = useNavigate();
    const [user, setUser] = useState<CurrentUserViewModel>({} as CurrentUserViewModel);
    const [userDetail, setUserDetail] = useState({} as UserDetailViewModel)
    const [isLoaded, setIsLoaded] = useState(false);
    const isMounted = useRef(true);
    const fullName = userDetail?.firstName + " " + userDetail?.lastName;

    const getUserInfo = () => {
        UserGateway.getUserInfo()
            .then((data: CurrentUserViewModel) => {
                setUser(data);
                localStorage.setItem('CurrentUser', String(data.userId));
            })
            .catch(() => {
                //Check routes that are trying to login and don't throw the error.
                const tryingToLoginRoutes = window.location.href
                if (tryingToLoginRoutes.indexOf("verifyEmail") > -1) return;
                if (tryingToLoginRoutes.indexOf("magicLink") > -1) return;
                if(window.location.origin.includes("truehouse") || window.location.origin.includes("vpo")) {
                    navigate("vpo/login", {relative: "route"});
                } else {
                    navigate("login", {relative: "route"});
                }
            })
    }

    const GetUserDetail = (id: number) => {
        const controller = new AbortController();
        UserGateway.getUser(id)
            .then(res => {
                setUserDetail(res)
                setIsLoaded(true);
            })
            .catch(e => {
                alert(e)
                navigate(LoginURLs.loginView);
            })
        return () => {
            isMounted.current = false;
            controller.abort();
        }
    }

    useEffect(() => {
        //We don't want please login popup on initial render
        if(loginPage) return;
        //Initial Load
        getUserInfo();
    }, [])

    useEffect(() => {
        //Check for user info every 15 minutes
        const interval = setInterval(() => {
            getUserInfo();
        }, 900000)
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        //If load is successful get the UserDetailViewModel
        if (user.userId !== undefined) {
            return GetUserDetail(user.userId)
        }
    }, [user.userId])

    //The populated state object that we send to the component that needs User information.
    const contextValue = {
        isLoaded: isLoaded,
        user: user,
        userDetail: userDetail,
        fullName: fullName
    }

    //What we wrap around our routes
    const {Provider} = UserContext;

    return (
        <Provider value={contextValue}>
            {children}
        </Provider>
    )
}