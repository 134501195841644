import {ISpecification} from "../../generics/Specification/ISpecification";
import {SpecificationResult} from "../../generics/Specification/SpecificationResult";
import {OrderEntryForm} from "../domain/OrderEntryForm";

export class OrderEntryFormSpecification implements ISpecification<OrderEntryForm> {
    public isSatisfiedBy(value: OrderEntryForm): SpecificationResult {
        if(value.customerField.value?.name.trim().length === 0 || !value.customerField.value) {
            return new SpecificationResult(false);
        } else if (value.subdivisionField.value?.name.trim().length === 0 || !value.subdivisionField.value){
            return new SpecificationResult(false);
        } else if (value.planField.value?.name.trim().length === 0 || !value.planField.value) {
            return new SpecificationResult(false);
        } else if (value.lotField.value?.name.trim().length === 0 || !value.lotField.value) {
            return new SpecificationResult(false);
        } else {
            return new SpecificationResult(true);
        }
    }
}