import {SortableOrder} from "../Sortable/SortableOrder";

export class SortViewModel {
    public readonly sortProperty: string;
    public readonly sortOrder: SortableOrder;
    
    constructor(sortProperty: string, sortOrder: SortableOrder = SortableOrder.Unsorted) {
        this.sortProperty = sortProperty;
        this.sortOrder = sortOrder;
    }
}