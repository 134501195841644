import {IDisciplineRepo} from "./IDisciplineRepo";
import {IDisciplineGateway} from "../gateways/IDisciplineGateway";
import {Discipline} from "../domain/Discipline";
import {DisciplinePhase} from "../../disciplinePhaseAggregate/domain/DisciplinePhase";

export class DisciplineRepo implements IDisciplineRepo {
    private readonly _disciplineGateway: IDisciplineGateway;

    constructor(disciplineGateway: IDisciplineGateway) {
        this._disciplineGateway = disciplineGateway;
    }

    public GetAllDisciplines = async (): Promise<Discipline[]> => {
        const disciplines = await this._disciplineGateway.getDisciplinesList();
        const mappedDisciplinesPromises = (disciplines).map(async (x) => {
            const disciplinePhaseListViewModel = await this.GetDisciplinePhasesByDisciplineId(x.id);
            const disciplinePhases = disciplinePhaseListViewModel.map(x => {
                return new DisciplinePhase(x.id, x.name, x.disciplineId, false);
            })
            return new Discipline(x.id, x.name, disciplinePhases, false);
        });

        return await Promise.all(mappedDisciplinesPromises);
    };

    public GetDisciplineNameById = async (id: number) => {
        return await this._disciplineGateway.getDisciplineById(id);
    }

    public async GetDisciplinePhasesByDisciplineId(id: number) {
        return await this._disciplineGateway.getDisciplinePhasesByDisciplineId(id);
    }
}