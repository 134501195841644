import {SortableOrder} from "../../generics/Sortable/SortableOrder";

export class TableTitle {
    private _id: number;
    protected _displayValue: string;
    //Value is only required if sorting is needed
    protected _value?: string;
    protected _sortOrder?: SortableOrder;


    constructor(id: number = 0, displayValue: string, value?: string, sortOrder?: SortableOrder) {
        this._id = id;
        this._displayValue = displayValue;
        this._value = value;
        this._sortOrder = sortOrder;
    }
    
    public updateSortOrder(sortOrder: SortableOrder): TableTitle {
        const result = this.clone();
        result._sortOrder = sortOrder;
        return result;
    }
    
    public clone(): TableTitle {
        return new TableTitle(this._id, this._displayValue, this._value, this._sortOrder);
    }

    get displayValue(): string {
        return this._displayValue;
    }

    get id(): number {
        return this._id;
    }

    get value(): string | undefined {
        return this._value;
    }

    get sortOrder(): SortableOrder | undefined {
        return this._sortOrder;
    }
}