import {ReactNode, useContext, useEffect, useRef, useState} from "react";
import {OmniSearchContext} from "./OmniSearchContext";
import {OmniSearchDomainObject} from "../domain/OmniSearchDomainObject";
import {OmniSearchLookupViewModel} from "../viewModels/OmniSearchLookupViewModel";
import {OmniSearchValueObject} from "../valueObjects/OmniSearchValueObject";
import {ServiceContext} from "../../infrastructure/ServiceContextProvider";

interface IOmniSearchContextProviderProps {
    children: ReactNode;
}
export const OmniSearchContextProvider = ({ children }: IOmniSearchContextProviderProps) => {
    const listInnerRef = useRef();
    const { OmniSearchGateway } = useContext(ServiceContext);
    const initialState = [{ category: "", options: [] }];
    const [options, setOptions] = useState<OmniSearchValueObject[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [query, setQuery] = useState("");
    const omniSearchDomainObject = new OmniSearchDomainObject(listInnerRef, isLoaded, initialState, options, selectedCategory, query);


    useEffect(() => {
        if (query === "") {
            omniSearchDomainObject.handleClearState();
        } else {
            OmniSearchGateway.Search(query.trim())
                .then((data: OmniSearchLookupViewModel) => {
                    setIsLoaded(false);
                    setOptions([
                        new OmniSearchValueObject("Customers", data.customerLookupViewModel),
                        new OmniSearchValueObject("Plans", data.planLookupViewModel),
                        new OmniSearchValueObject("Subdivisions", data.subdivisionLookupViewModel),
                        new OmniSearchValueObject("Lots", data.lotLookupViewModel)
                    ]);
                    setIsLoaded(true);
                })
                .catch((err: Error) => {
                    console.log(err);
                })
        }
    }, [query]);
    
    return (
        <OmniSearchContext.Provider value={{
            omniSearchDomainObject,
            options,
            selectedCategory,
            initialState,
            isLoaded,
            query,
            listInnerRef,
            setQuery,
            setSelectedCategory,
        }}>
            {children}
        </OmniSearchContext.Provider>
    )
};