import {PricingMatrix} from "../Domain/PricingMatrix";
import {PricingMatrixUnitType} from "../Domain/PricingMatrixUnitType";
import {PricingMatrixEditViewModel} from "../ViewModels/PricingMatrixEditViewModel";
import {IPricingMatrixField} from "../Domain/IPricingMatrixField";
import {PricingMatrixField} from "../Domain/PricingMatrixField";
import {InputGroupTypes} from "../../globalComponents/InputGroupTypes";
import {PricingMatrixBillingTypeDropdownOptions} from "../UI/PricingMatrixBillingTypeDropdownOptions";
import {PricingMatrixDiscountDropdownOptions} from "../UI/PricingMatrixDiscountDropdownOptions";

export class PricingMatrixFactory {
    public createEmptyPricingMatrix(disciplinePhaseId: number) {
        return new PricingMatrix(
            0,
            disciplinePhaseId,
            0,
            this.createNewPricingMatrixBillingField("Base Elevation SFUR", "Base Elevation SFUR"),
            PricingMatrixUnitType.Price,
            this.createNewPricingMatrixBillingField("Additional Elevation SFUR", "Additional Elevation SFUR"),
            PricingMatrixUnitType.Price,
            this.createNewPricingMatrixBillingField("Additional Foundations", "Additional Foundations"),
            this.createNewPricingMatrixBillingField("Major Options", "Major Options"),
            this.createNewPricingMatrixBillingField("Minor Options", "Minor Options"),
            this.createNewPricingMatrixDiscountField("Bonus Discount", "Bonus Discount"),
            PricingMatrixUnitType.Amount,
            this.createNewPricingMatrixBillingField("Additional Solar Orientations", "Additional Solar Orientations"),
        );
    }

    public createPopulatedPricingMatrix(id: number, disciplineSetId: number, disciplinePhaseId: number, baseElevationSfur: IPricingMatrixField, baseElevationSfurUnitType: PricingMatrixUnitType, additionalElevationSfur: IPricingMatrixField, additionalElevationSfurUnitType: PricingMatrixUnitType, additionalFoundations: IPricingMatrixField, majorOptions: IPricingMatrixField, minorOptions: IPricingMatrixField, bonusDiscount: IPricingMatrixField, bonusDiscountType: PricingMatrixUnitType, additionalSolarOrientations: IPricingMatrixField) {
        return new PricingMatrix(
            id,
            disciplinePhaseId,
            disciplineSetId,
            baseElevationSfur,
            baseElevationSfurUnitType,
            additionalElevationSfur,
            additionalElevationSfurUnitType,
            additionalFoundations,
            majorOptions,
            minorOptions,
            bonusDiscount,
            bonusDiscountType,
            additionalSolarOrientations,
        );
    }

    public createPricingMatrixFromEditViewModel(pricingMatrixEditViewModel: PricingMatrixEditViewModel, disciplinePhaseId: number): PricingMatrix {
        return new PricingMatrix(
            pricingMatrixEditViewModel.id,
            disciplinePhaseId,
            pricingMatrixEditViewModel.disciplineSetId,
            new PricingMatrixField(pricingMatrixEditViewModel.baseElevationSfur.toString(), PricingMatrixUnitType.Price, "Base Elevation SFUR", InputGroupTypes.Currency, PricingMatrixBillingTypeDropdownOptions, "Base Elevation SFUR"),
            pricingMatrixEditViewModel.baseElevationSfurUnitType,
            new PricingMatrixField(pricingMatrixEditViewModel.additionalElevationSfur.toString(), pricingMatrixEditViewModel.additionalElevationSfurUnitType, "Additional Elevation SFUR", InputGroupTypes.Currency, PricingMatrixBillingTypeDropdownOptions, "Additional Elevation SFUR"),
            pricingMatrixEditViewModel.additionalElevationSfurUnitType,
            new PricingMatrixField(pricingMatrixEditViewModel.additionalFoundations.toString(), PricingMatrixUnitType.Price, "Additional Foundations", InputGroupTypes.Currency, PricingMatrixBillingTypeDropdownOptions, "Additional Foundations"),
            new PricingMatrixField(pricingMatrixEditViewModel.majorOptions.toString(), PricingMatrixUnitType.Price, "Major Options", InputGroupTypes.Currency, PricingMatrixBillingTypeDropdownOptions, "Major Options"),
            new PricingMatrixField(pricingMatrixEditViewModel.minorOptions.toString(), PricingMatrixUnitType.Price, "Minor Options", InputGroupTypes.Currency, PricingMatrixBillingTypeDropdownOptions, "Minor Options"),
            new PricingMatrixField(pricingMatrixEditViewModel.bonusDiscount.toString(), pricingMatrixEditViewModel.bonusDiscountType, "Bonus Discount", InputGroupTypes.Currency, PricingMatrixDiscountDropdownOptions, "Bonus Discount"),
            pricingMatrixEditViewModel.bonusDiscountType,
            new PricingMatrixField(pricingMatrixEditViewModel.additionalSolarOrientations.toString(), PricingMatrixUnitType.Each, "Additional Solar Orientations", InputGroupTypes.Currency, PricingMatrixBillingTypeDropdownOptions, "Additional Solar Orientations"),
        )
    }

    public createNewPricingMatrixBillingField(label: string, name: string): IPricingMatrixField {
        return new PricingMatrixField("0", PricingMatrixUnitType.Price, label, InputGroupTypes.Currency, PricingMatrixBillingTypeDropdownOptions, name)
    }

    public createNewPricingMatrixDiscountField(label: string, name: string): IPricingMatrixField {
        return new PricingMatrixField("0", PricingMatrixUnitType.Amount, label, InputGroupTypes.Currency, PricingMatrixDiscountDropdownOptions, name)
    }
}
