export enum AddressSelection {
    Shipping = "Use Shipping Address",
    Billing = "Use Billing Address",
    Default = "Select billing or shipping address"
}

export class AddressOptions {
    public readonly id: number;
    public readonly name: AddressSelection;
    
    constructor(id: number, name: AddressSelection) {
        this.id = id
        this.name = name
    }
}