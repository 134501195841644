import {TableTitle} from "../../globalComponents/Table/TableTitle";

export enum BuildingDepartmentRowPropertyValues {
    City = "City",
    County = "County",
    State = "State"
}

export class BuildingDepartmentRow {
    private readonly _id: number;
    private _name: string;
    private _city: Map<number, string>;
    private _county: Map<number, string>;
    private _state: Map<number, string>;
    protected _isActionPanelOpen: boolean


    constructor(id: number, name: string, city: Map<number, string>, county: Map<number, string>, state: Map<number, string>, isActionPanelOpen: boolean = false) {
        this._id = id;
        this._name = name;
        this._city = city;
        this._county = county;
        this._state = state;
        this._isActionPanelOpen = isActionPanelOpen;
    }

    public clone(): BuildingDepartmentRow {
        return new BuildingDepartmentRow(this._id, this._name, this._city, this._county, this._state, this._isActionPanelOpen);
    }
    
    //region state methods
    public toggleActionPanelVisibility(): BuildingDepartmentRow {
        const result = this.clone();
        result._isActionPanelOpen = !result._isActionPanelOpen;
        return result;
    }

    public closeActionPanel(): BuildingDepartmentRow{
        const result = this.clone()
        result._isActionPanelOpen = false;
        return result;
    }
    
    //endregion
    
    public getBuildingDepartmentArrangeablePropertiesStrategy(tableTitle: TableTitle) {
        switch(tableTitle.displayValue) {
            case BuildingDepartmentRowPropertyValues.City:
                return this.getBuildingDepartmentCityByColumnId(tableTitle.id);
            case BuildingDepartmentRowPropertyValues.County:
                return this.getBuildingDepartmentCountyByColumnId(tableTitle.id);
            case BuildingDepartmentRowPropertyValues.State: 
                return this.getBuildingDepartmentStateByColumnId(tableTitle.id);
            default:
                return "N/A";
        }
    }
    
    private getBuildingDepartmentCityByColumnId(columnId: number) {
        return this._city.get(columnId);
    }
    
    private getBuildingDepartmentCountyByColumnId(columnId: number) {
        return this._county.get(columnId);
    }
    
    private getBuildingDepartmentStateByColumnId(columnId: number) {
        return this._state.get(columnId);
    }
    
    //region getters
    get id(): number {
        return this._id;
    }
    
    get name(): string {
        return this._name;
    }

    get city(): Map<number, string> {
        return this._city;
    }

    get county(): Map<number, string> {
        return this._county;
    }

    get state(): Map<number, string> {
        return this._state;
    }

    get isActionPanelOpen(): boolean {
        return this._isActionPanelOpen;
    }
    //endregion
}