import {IMagicLinkGateway} from "./IMagicLinkGateway";
import {MagicLinkCreateViewModel} from "../viewModels/MagicLinkCreateViewModel";
import {handleJsonResponse} from "../../helperFunctions/handleJsonResponse";

export default class MagicLinkGateway implements IMagicLinkGateway {
    private readonly baseUrl: string = '/api/magicLinks';
    
    GetMagicLink = async (hash: string, signal: AbortSignal) => {
        return await this.GetRequest(`/decode/${hash}`, signal);
    };

    CreateMagicLink = async (magicLinkCreateViewModel: MagicLinkCreateViewModel, urlPath: string) => {
        //urlPath determines whether or not its an external or internal endpoint call
        return await this.PostRequest(urlPath, magicLinkCreateViewModel);
    };

    private async PostRequest(path: string, requestObject: object) {
        const url = this.baseUrl + path;
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: requestObject ? JSON.stringify(requestObject) : ""
        };

        return fetch(url, requestOptions)
            .then(handleJsonResponse)
            .then((data) => {
                return data;
            })
    }

    private async GetRequest(path: string, signal: AbortSignal) {
        const url = this.baseUrl + path;
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            signal: signal
        };

        return fetch(url, requestOptions)
            .then(handleJsonResponse)
            .then((data) => {
                return data;
            })
    }
} 