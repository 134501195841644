import {ILoadable} from "../../generics/Loadable/ILoadable";
import {TableTitle} from "../../globalComponents/Table/TableTitle";
import {TableSort} from "../../globalComponents/Table/TableSort";
import {ISortable} from "../../generics/Sortable/ISortable";
import {SubdivisionLotTableData} from "./SubdivisionLotTableData";
import {LotCheckStatus} from "../../lotAggregate/LotCheckStatus";
import {TableColumnArranger} from "../../globalComponents/Table/columnRearrange/TableColumnArranger";

export class SubdivisionLotsTable extends TableSort implements ILoadable<SubdivisionLotsTable>, ISortable {
    protected _id: number;
    protected _name: string;
    protected _lotsList: SubdivisionLotTableData[];
    protected _lotIdsSelectedForBulk: number[];
    protected _isLoading: boolean;
    protected _isActionPanelOpen: boolean;
    private _tableColumnArranger: TableColumnArranger;

    constructor(id: number, name: string, lotsList: SubdivisionLotTableData[], tableTitles: TableTitle[], lotIdsSelectedForBulk: number[] = [], isLoading: boolean = true, isActionPanelOpen: boolean = false, tableColumnArranger: TableColumnArranger) {
        super(tableTitles);
        this._id = id;
        this._name = name;
        this._lotsList = lotsList;
        this._lotIdsSelectedForBulk = lotIdsSelectedForBulk;
        this._isLoading = isLoading;
        this._isActionPanelOpen = isActionPanelOpen;
        this._tableColumnArranger = tableColumnArranger;
    }

    protected clone(): this {
        const lotsListCopy = [...this._lotsList];
        const tableHeadersCopy = [...this._tableTitles];
        const lotIdsCopy = [...this._lotIdsSelectedForBulk];
        return new SubdivisionLotsTable(this._id, this._name, lotsListCopy, tableHeadersCopy, lotIdsCopy, this._isLoading, this._isActionPanelOpen, this._tableColumnArranger) as this;
    }

    public hasUserSettings(tableTitles: TableTitle[], tableSettings: number[]): boolean {
        return tableTitles.slice(1).length === tableSettings?.length;
    }

    public applyUserSettings(columnIds: number[]): SubdivisionLotsTable {
        const result = this.clone();
        //First Item in array (Lot Name) can't be rearranged.
        const firstItem = result._tableTitles[0];
        let slicedTitles = result._tableTitles.slice(1);

        //Sort array, excluding the first item
        slicedTitles = columnIds?.map(id => {
            return slicedTitles.find(x => x.id === id)!;
        });

        //Add the first item back to the array
        //Array check here is for moq.ts. I believe it might struggle to recognize the spread operator.
        if (Array.isArray(slicedTitles)) {
            result._tableTitles = [firstItem, ...slicedTitles];
        }
        return result;
    }

    //region getters
    get lotsList(): SubdivisionLotTableData[] {
        return this._lotsList;
    }

    get tableColumnArranger(): TableColumnArranger {
        return this._tableColumnArranger;
    }

    get isActionPanelOpen(): boolean {
        return this._isActionPanelOpen;
    }

    get id(): number {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    get lotIdsSelectedForBulk(): number[] {
        return this._lotIdsSelectedForBulk;
    }

    //endregion

    //region react state methods

    public updateTableColumnArranger(tableColumnArranger: TableColumnArranger): SubdivisionLotsTable {
        const result = this.clone();
        result._tableColumnArranger = tableColumnArranger;
        return result;
    }

    public updateLotsList(lot: SubdivisionLotTableData): SubdivisionLotsTable {
        const result = this.clone();
        const index = result._lotsList.findIndex(x => x.id === lot.id);
        result._lotsList.splice(index, 1, lot);
        return result;
    }

    public openActionMenu(): SubdivisionLotsTable {
        const result = this.clone();
        result._isActionPanelOpen = true;
        return result;
    }

    public toggleVisibilityOfActionMenuPopup(): SubdivisionLotsTable {
        const result = this.clone();
        result._isActionPanelOpen = !result._isActionPanelOpen;
        return result;
    }

    public determineIfLotIsToBeAddedOrRemoved(lotId: number, lotStatus: boolean): SubdivisionLotsTable {
        if (lotStatus) {
            return this._addLotToLotBulkBatch(lotId);
        } else {
            return this._removeLotFromLotBulkBatch(lotId);
        }
    }

    public determineIfMultipleLotsAreToBeAddedOrRemoved(lotStatus: LotCheckStatus): SubdivisionLotsTable {
        if (lotStatus === LotCheckStatus.Selected) {
            return this._addAllLotsToLotBulkBatch();
        } else if (lotStatus === LotCheckStatus.UnSelected) {
            return this._removeAllLotsFromLotBulkBatch();
        } else {
            return this.clone();
        }
    }

    private _addAllLotsToLotBulkBatch(): SubdivisionLotsTable {
        const result = this.clone();
        result._lotIdsSelectedForBulk = [];
        result._lotsList.map(x => result._lotIdsSelectedForBulk.push(x.id));
        return result;
    }

    private _addLotToLotBulkBatch(lotId: number): SubdivisionLotsTable {
        const result = this.clone();
        result._lotIdsSelectedForBulk.unshift(lotId);
        result._lotsList = result._lotsList.map(x => {
            if (x.id === lotId) {
                return x.toggleIsChecked();
            }
            return x;
        });

        return result;
    }

    private _removeAllLotsFromLotBulkBatch(): SubdivisionLotsTable {
        const result = this.clone();
        result._lotIdsSelectedForBulk = [];
        return result;
    }

    private _removeLotFromLotBulkBatch(lotId: number): SubdivisionLotsTable {
        const result = this.clone();
        const findIndexOfLotToRemove = result._lotIdsSelectedForBulk.findIndex(id => id === lotId);
        result._lotIdsSelectedForBulk.splice(findIndexOfLotToRemove, 1);
        result._lotsList = result._lotsList.map(x => {
            if (x.id === lotId) {
                return x.setIsCheckedToFalse();
            }
            return x;
        });
        return result;
    }

    public finishLoading(): SubdivisionLotsTable {
        const result = this.clone();
        result._isLoading = false;
        return result;
    }

    public toggleIsLoading(): SubdivisionLotsTable {
        const result = this.clone();
        result._isLoading = !result._isLoading;
        return result;
    }

    public setSubdivisionIdFromPageUrlRoute(id: number) {
        const result = this.clone();
        result._id = id;
        return result;
    }

    //endregion

    //region factory methods
    public initializeTableToSendDefaultSortOrderToBackend(): SubdivisionLotsTable {
        return new SubdivisionLotsTable(0, "", [], this._tableTitles, this._lotIdsSelectedForBulk, this._isLoading, this._isActionPanelOpen, this._tableColumnArranger);
    }

    public createSubdivisionLotsTableWithValidData(id: number, name: string, lotsList: SubdivisionLotTableData[], titles: TableTitle[], tableColumnArranger: TableColumnArranger): SubdivisionLotsTable {
        return new SubdivisionLotsTable(id, name, lotsList, titles, [], false, false, tableColumnArranger);
    }

    //endregion
}