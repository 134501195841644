import {ISystemSettingsGateway} from "./ISystemSettingsGateway";
import {SystemSettingsUpdateViewModel} from "../../pages/Settings/ViewModels/SystemSettingsUpdateViewModel";
import {handleJsonResponse} from "../../helperFunctions/handleJsonResponse";

export class SystemSettingsGateway implements ISystemSettingsGateway {
    baseUrl: string = '/api/systemSettings/';

    public async GetSystemSettingsByUserId(): Promise<SystemSettingsUpdateViewModel> {
       return this.GetRequest("");
    }

    public async UpdateSystemSettings(updateViewModel: SystemSettingsUpdateViewModel, controller: AbortSignal) {
       return this.PutRequest("update", updateViewModel, controller);
    }

    private async GetRequest(path: string, signal?: AbortSignal) {
        const url = this.baseUrl + path;
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            signal: signal
        };

        return fetch(url, requestOptions)
            .then(handleJsonResponse)
            .then((data) => {
                return data;
            })
    }

    private async PutRequest(path: string, requestObject: object, signal: AbortSignal) {
        const url = this.baseUrl + path;
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: requestObject ? JSON.stringify(requestObject) : "",
            signal: signal
        };

        return fetch(url, requestOptions)
            .then(handleJsonResponse)
            .then((data) => {
                return data;
            })
    }
}