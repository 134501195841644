import {ProjectTeam} from "../../projectTeamAggregate/domain/ProjectTeam";
import {PricingMatrix} from "../../pricingMatrixAggregate/Domain/PricingMatrix";
import {Discipline} from "../../disciplineAggregate/domain/Discipline";
import {DisciplinePhase} from "../../disciplinePhaseAggregate/domain/DisciplinePhase";


export class RequirementSet {
    private _id: number;
    private _disciplines: Array<Discipline>;

    constructor(id: number, disciplines: Array<Discipline>) {
        this._id = id;
        this._disciplines = disciplines;
    }
    
    private clone() {
        return new RequirementSet(this._id, this._disciplines);
    }
    
    public getSelectedDisciplines() {
        return this._disciplines.filter(x => x.isSelected);
    }

    /**
     * Looks in memory for the discipline given that discipline Id and sets the is selected property of that discipline to true
     * @param disciplineId
     */
    public selectDiscipline(disciplineId: number): RequirementSet{
        const result = this.clone();
        const index = result._disciplines.findIndex(x => x.id === disciplineId);
        let newDiscipline = result._disciplines.find(x => x.id === disciplineId)!;
        newDiscipline = newDiscipline.setIsSelected(true);
        result._disciplines.splice(index, 1, newDiscipline);
        return result;
    }

    /**
     * Looks in memory for the discipline that matches the discipline Id passed in and sets the is selected property to false
     * @param disciplineId
     */
    public deselectDiscipline = (disciplineId: number) => {
        const result = this.clone();
        const index = result._disciplines.findIndex(x => x.id === disciplineId);
        let newDiscipline = result._disciplines.find(x => x.id === disciplineId)!;
        newDiscipline = newDiscipline.setIsSelected(false);
        result._disciplines.splice(index, 1, newDiscipline);
        return result;
    }

    public addDisciplinePhaseToRequirementSetDiscipline = (disciplinePhase: DisciplinePhase) => {
        const matchedDiscipline = this._disciplines.find((x => {
            return disciplinePhase.disciplineId === x.id;
        }));

        if (!matchedDiscipline) {
            throw new Error("Unable to add discipline phase");
        }
        matchedDiscipline.addDisciplinePhase(disciplinePhase);

        return new RequirementSet(this._id, this._disciplines);
    }

    public removeDisciplinePhaseFromRequirementSetDiscipline = (disciplinePhase: DisciplinePhase) => {
        const matchedDiscipline = this._disciplines.find((x => {
            return x.id === disciplinePhase.disciplineId;
        }));

        if (!matchedDiscipline) {
            throw new Error("Unable to remove discipline phase");
        }

        matchedDiscipline?.removeDisciplinePhase(disciplinePhase);
        return new RequirementSet(this._id, this._disciplines);
    }

    public addRequirementSetDisciplinePhaseProjectTeam = (projectTeam: ProjectTeam) => {
        if(projectTeam.userSkillSetProjectTeamLinks.length < 1) {
            throw new Error("You must have at least one user assigned to a skill set.");
        }
        const matchedDiscipline = this._disciplines.find((x => {
            return x.disciplinePhases.some(y => {
                return y.projectTeam?.id === projectTeam.id;
            })
        }));

        if (!matchedDiscipline) {
            throw new Error("Unable to add discipline phase project team");
        }

        const matchedDisciplinePhase = matchedDiscipline.disciplinePhases.find(x => {
            return x.projectTeam?.disciplinePhaseId === projectTeam.disciplinePhaseId;
        });

        matchedDisciplinePhase?.updateDisciplinePhaseProjectTeam(projectTeam);
        return new RequirementSet(this._id, this._disciplines);
    }

    public addRequirementSetDisciplinePhasePricingMatrix = (pricingMatrix: PricingMatrix) => {
        const matchedDiscipline = this._disciplines.find((x => {
            return x.disciplinePhases.some(y => {
                return y.pricingMatrix?.id === pricingMatrix.id;
            });
        }));

        const matchedDisciplinePhase = matchedDiscipline?.disciplinePhases.find(x => {
            return x.id === pricingMatrix.disciplinePhaseId;
        });
        matchedDisciplinePhase?.updateDisciplinePhasePricingMatrix(pricingMatrix);
        return new RequirementSet(this._id, this._disciplines);
    }
    
    get disciplines(): Array<Discipline> {
        return this._disciplines;
    }
    
    get id(): number {
        return this._id;
    }
}