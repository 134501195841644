import {TableType} from "../../globalComponents/Table/TableType";

export class UserTableSettingsViewModel {
    public readonly tableType: TableType;
    public readonly tableHeaderIds: number[];
    
    constructor(tableType: TableType, tableHeaderIds: number[]) {
        this.tableType = tableType;
        this.tableHeaderIds = tableHeaderIds;
    }
}