import {ISpecification} from "./ISpecification";
import {SpecificationResult} from "./SpecificationResult";

export class ZipCodeSpecification implements ISpecification<string> {
    public isSatisfiedBy(value: string): SpecificationResult {
        if (value.toLowerCase().includes("mix")) {
            //For bulk actions if two different items have different values, the word mix, mixed value, mixed, etc, is allowed because if the backend gets that string, it will not update
            return new SpecificationResult(true);
        } else if(value.length !== 5) {
            return new SpecificationResult(false, "Zip code has invalid syntax. Zip code should only be 5 characters long.");
        } else if (/[a-zA-Z]/.test(value)) {
            return new SpecificationResult(false, "Zip code must contain numeric values only.");
        } else {
            return new SpecificationResult(true);
        }
    }
}