import {IAPIGateway} from "../../infrastructure/IAPIGateway";
import {IBuildingDepartmentGateway} from "./IBuildingDepartmentGateway";
import {BuildingDepartmentListViewModel} from "../viewModels/BuildingDepartmentListViewModel";
import {BuildingDepartmentEditViewModel} from "../viewModels/BuildingDepartmentEditViewModel";
import {BuildingDepartmentTableRowViewModel} from "../viewModels/BuildingDepartmentTableRowViewModel";

export class BuildingDepartmentGateway implements IBuildingDepartmentGateway {
    baseUrl: string = '/api/buildingDepartment'
    private readonly _apiGateway: IAPIGateway;
    
    constructor(apiGateway: IAPIGateway) {
        this._apiGateway = apiGateway;
    }
    
    public async getAllBuildingDepartments(): Promise<BuildingDepartmentListViewModel[]> {
        return await this._apiGateway.get(`${this.baseUrl}/list`);
    }
    
    public async getBuildingDepartmentFormDetailsById(buildingDepartmentId: number): Promise<BuildingDepartmentEditViewModel> {
        return await this._apiGateway.get(`${this.baseUrl}/${buildingDepartmentId}/form`);
    }
    
    public async addBuildingDepartment(buildingDepartmentEditVM: BuildingDepartmentEditViewModel): Promise<number> {
        return await this._apiGateway.post(`${this.baseUrl}`, buildingDepartmentEditVM);
    }
    
    public async updateBuildingDepartmentForm(id: number, buildingDepartmentEditVM: BuildingDepartmentEditViewModel): Promise<void> {
        return await this._apiGateway.put(`${this.baseUrl}/${id}/form`, buildingDepartmentEditVM);
    }
    
    public async getAllBuildingDepartmentsForTable(): Promise<BuildingDepartmentTableRowViewModel[]> {
        return await this._apiGateway.get(`${this.baseUrl}/list/table`);
    }
}