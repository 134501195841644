import {OrderEntryForm} from "./OrderEntryForm";
import {OrderEntryEmail} from "./OrderEntryEmail";
import {BatchStatus} from "./BatchStatus";
import {TableTitle} from "../../globalComponents/Table/TableTitle";
import {OrderEntryStatus} from "./OrderEntryStatus";
import {OrderEntryFormMode} from "./OrderEntryFormMode";
import {FileUpload} from "../../globalComponents/fileUpload/FileUpload";
import {Percentage} from "../../valueObjects/percentage/Percentage";

export class OrderEntryBatchForm {
    protected _batchId: string;
    private _title: string;
    private _canAddNewOrder: boolean;
    private readonly _tableTitles: TableTitle[];
    protected _orderEntryForms: OrderEntryForm[];
    protected _batchEmails: OrderEntryEmail[];
    private _batchStatus: BatchStatus;
    private _isLoading: boolean;
    private _newEmailUpload: FileUpload;

    constructor(batchId: string, orderEntries: OrderEntryForm[] = [], batchEmails: OrderEntryEmail[] = [], batchStatus: BatchStatus, isLoading: boolean = false, newEmailUpload: FileUpload = new FileUpload()) {
        this._tableTitles = [
            new TableTitle(1, "#"),
            new TableTitle(2, "Customer"),
            new TableTitle(3, "Subdivision"),
            new TableTitle(4, "Lot"),
            new TableTitle(5, "Plan"),
            new TableTitle(6, "Garage Orientation"),
            new TableTitle(7, "Component Manufacturer"),
            new TableTitle(8, "Trusses In"),
            new TableTitle(9, " "),
        ];
        this._batchId = batchId;
        this._title = "Orders";
        this._orderEntryForms = orderEntries;
        this._batchEmails = batchEmails;
        this._batchStatus = batchStatus;
        this._canAddNewOrder = this.determineIfNewOrderCanBeAdded();
        this.isActionMenuAvailable();
        this._isLoading = isLoading;
        this._newEmailUpload = newEmailUpload;
    }

    //region private methods

    private clone() {
        return new OrderEntryBatchForm(this._batchId, [...this._orderEntryForms], [...this._batchEmails], this._batchStatus, this._isLoading, this._newEmailUpload);
    }

    private determineIfNewOrderCanBeAdded(): boolean {
        return !this._orderEntryForms.some(x => x.orderEntryStatus === OrderEntryStatus.InProgress);
    }

    public hasTableHeader(): boolean {
        return !(this._orderEntryForms.length === 1 && this._orderEntryForms.every(x => x.orderEntryStatus !== OrderEntryStatus.Completed));
    }

    /**
     * This method checks if all the orders have an email association.
     * It is a little complex, but essentially for every order entry, we check that each order entry email has at least one order entry email association where that association has an ID that matches the order entry ID and for that one that it is also selected. If that happens, then we add that order entry Id to a TypeScript Set object. In the UI, the association is determined by whether isSelected is true or false on the OrderEntryAssociations instead of directly checking that the IDs match. Finally, if every order entry id, has a matching value in the set, this will return true, otherwise it returns false
     */
    public areAllOrdersAssociatedWithAnEmail(): boolean {
        const orderEntryIds = this._orderEntryForms.map(x => x.id);
        const orderAssociationSet = new Set<string>();

        for (const orderEntryEmail of this._batchEmails) {
            if (orderEntryEmail.areAllOrdersSelected) {
                return true;
            }
            for (const orderEntryId of orderEntryIds) {
                if (orderEntryEmail.orderEntryAssociations.some(y => {
                    return (y.orderEntryId === orderEntryId) && y.isSelected;
                })) {
                    orderAssociationSet.add(orderEntryId);
                }
            }
        }

        return orderEntryIds.every(x => {
            return orderAssociationSet.has(x);
        })
    }

    public initiateNewEmailUpload() {
        const result = this.clone();
        result._newEmailUpload = result._newEmailUpload.setIsLoading(true);
        return result;
    }

    public canSubmitOrderEntryBatch() {
        const result1 = this._orderEntryForms.every(x => x.orderEntryStatus === OrderEntryStatus.Completed);
        const result2 = this.areAllOrdersAssociatedWithAnEmail();

        return (result1 && result2);
    }

    public hasEmailsThatAreUnassociated(): boolean {
        return this._batchEmails.some(x => x.orderEntryAssociations.every(y => !y.isSelected))
    }

    //endregion

    //region state methods

    /**
     * This method is only used within a single order entry form component. This function is called in that component anytime the local state in that component changes. Hence the naming "notifyOrderEntryFormChanged". Having this called will ensure that the state stays in sync between each local state within an order entry form and the entire order entry batch
     * @param orderEntry
     */
    public notifyOrderEntryFormChanged(orderEntry: OrderEntryForm): OrderEntryBatchForm {
        const result = this.clone();
        const orderEntryFormIndex = this._orderEntryForms.findIndex(x => x.id === orderEntry.id);

        const orderEntryToUpdate = this._orderEntryForms.find(x => x.id === orderEntry.id);

        if (!orderEntryToUpdate) {
            throw new Error(`Order Entry could not be found.`);
        }

        result._orderEntryForms.splice(orderEntryFormIndex, 1, orderEntry);

        return result;
    }

    public changeOrderEntryStatusByOrderEntryId(orderEntryId: string, orderEntryStatus: OrderEntryStatus): OrderEntryBatchForm {
        const result = this.clone();

        const orderEntryForm = this._orderEntryForms.find(x => x.id === orderEntryId);

        if (!orderEntryForm) {
            throw Error('Order Entry Form does not exist in Order Entry Batch.');
        }

        const newOrderEntryForm = orderEntryForm
            ?.setOrderEntryStatus(orderEntryStatus)
            .setOrderEntryFormMode(OrderEntryFormMode.Edit)

        const findIndexOfOrderEntryForm = this._orderEntryForms.findIndex(x => x.id === orderEntryId);

        result._orderEntryForms.splice(findIndexOfOrderEntryForm, 1, newOrderEntryForm);
        result._canAddNewOrder = result.determineIfNewOrderCanBeAdded();

        return result;
    }

    /**
     * This method works like a callback. Any time there are any changes to the batch email state in the UI, this method is called via the useEffect hook.
     * @param orderEntryEmail
     */
    public updateOrderEntryBatchEmails(orderEntryEmail: OrderEntryEmail): OrderEntryBatchForm {
        const result = this.clone();
        const orderEntryEmailIndex = this._batchEmails.findIndex(x => x.id === (orderEntryEmail.id));

        const orderEntryEmailToUpdate = this._batchEmails.find(x => x.id === (orderEntryEmail.id));

        //If Batch Email has been removed, just return the current state, otherwise, go ahead with update.
        if (!orderEntryEmailToUpdate) {
            return result;
        }

        result._batchEmails.splice(orderEntryEmailIndex, 1, orderEntryEmail);

        return result;
    }

    public setIsLoading(isLoading: boolean) {
        const result = this.clone();
        result._isLoading = isLoading;
        return result;
    }

    public setNewEmailUploadStatus(percentage: Percentage): OrderEntryBatchForm {
        const result = this.clone();
        result._newEmailUpload = result._newEmailUpload.updateUploadStatus(percentage);
        return result;
    }

    public setNewEmailUploadSuccessStatus(isSuccessful: boolean) {
        const result = this.clone();
        result._newEmailUpload = result._newEmailUpload.setIsSuccessful(isSuccessful);
        return result;
    }
    
    public setNewEmailUploadResponseMessage(response: string) {
        const result = this.clone();
        result._newEmailUpload = result._newEmailUpload.setResultMessage(response);
        return result;
    }

    public clearEmailUpload() {
        const result = this.clone();
        result._newEmailUpload = new FileUpload();
        return result;
    }

    //endregion

    //region factory methods
    public static initialize(batchId: string) {
        return new OrderEntryBatchForm(batchId, [], [], BatchStatus.New);
    }

    //endregion

    //region getters

    public getInProgressOrderEntries(): OrderEntryForm[] {
        return this._orderEntryForms.filter(x => x.orderEntryStatus === OrderEntryStatus.InProgress);
    }

    public getCompletedOrderEntries(): OrderEntryForm[] {
        return this._orderEntryForms.filter(x => x.orderEntryStatus === OrderEntryStatus.Completed);
    }

    /**
     * Determines when orderEntryForms should display an action menu
     */
    public isActionMenuAvailable(): void {
        let showActionMenu = true;
        const onlyOneOrderInBatch = (this._orderEntryForms.length === 1);

        if (onlyOneOrderInBatch) {
            const inEditState = (this._orderEntryForms[0].orderEntryStatus === OrderEntryStatus.InProgress);
            showActionMenu = !inEditState;
        }

        this._orderEntryForms.forEach(orderEntryForm => {
            orderEntryForm.isActionMenuAvailable = showActionMenu;
        });
    }
    
    public isBatchEmailActionMenuAvailable() {
        return this._batchEmails.length > 1;
    }
    
    public canEditOrder(orderEntryId: string): boolean {
        const orderEntry = this._orderEntryForms.find(x => x.id === orderEntryId);
        return orderEntry?.orderEntryStatus !== OrderEntryStatus.InProgress;
    }

    public canRemoveOrder(): boolean {
        return this._orderEntryForms.length > 1;
    }

    get batchId(): string {
        return this._batchId;
    }

    get isLoading(): boolean | undefined {
        return this._isLoading;
    }

    get canAddNewOrder(): boolean {
        return this._canAddNewOrder;
    }

    get tableTitles(): TableTitle[] {
        return this._tableTitles;
    }

    get title(): string {
        return this._title;
    }

    get orderEntryForms(): OrderEntryForm[] {
        return this._orderEntryForms;
    }

    get batchEmails(): OrderEntryEmail[] {
        return this._batchEmails;
    }

    get batchStatus(): BatchStatus {
        return this._batchStatus;
    }

    get newEmailUpload(): FileUpload {
        return this._newEmailUpload;
    }

//endregion
}