import {Lookup} from "../globalComponents/lookup/Lookup";

export enum GarageOrientationEnum {
    Left,
    Right
}

export const garageOrientationOptions = [
    new Lookup(GarageOrientationEnum.Left, "Left"),
    new Lookup(GarageOrientationEnum.Right, "Right")
]