import {ISpecification} from "./ISpecification";
import {SpecificationResult} from "./SpecificationResult";

export class CitySpecification implements ISpecification<string> {
    public isSatisfiedBy(value: string): SpecificationResult {
        if(/\d/.test(value)) {
            return new SpecificationResult(false, "Cities cannot have numbers as inputs.");
        } else {
            return new SpecificationResult(true);
        }
    }
}