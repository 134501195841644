export class OrderEntryAssociationViewModel {
    public readonly id: string;
    public readonly orderEntryId: string;
    public readonly orderEntryEmailId: string;

    constructor(id: string, orderEntryId: string, orderEntryEmail: string) {
        this.id = id;
        this.orderEntryId = orderEntryId;
        this.orderEntryEmailId = orderEntryEmail;
    }
}