import {ISubdivisionGateway} from "./ISubdivisionGateway";
import {IAPIGateway} from "../../infrastructure/IAPIGateway";
import {SubdivisionEditViewModel} from "../viewModels/SubdivisionEditViewModel";
import {LotEditViewModel} from "../../lotAggregate/viewModels/LotEditViewModel";
import {
    BuildingDepartmentListViewModel
} from "../../buildingDepartmentAggregate/viewModels/BuildingDepartmentListViewModel";
import {SubdivisionDetailsViewModel} from "../viewModels/SubdivisionDetailsViewModel";
import {SubdivisionLookupViewModel} from "../viewModels/SubdivisionLookupViewModel";
import {LotTableDetailViewModel} from "../../lotAggregate/viewModels/LotTableDetailViewModel";
import {LotBulkDeleteViewModel} from "../../lotAggregate/viewModels/LotBulkDeleteViewModel";
import {SortViewModel} from "../../generics/viewModels/SortViewModel";
import { LotBulkEditViewModel } from "lotAggregate/viewModels/LotBulkEditViewModel";
import { LotCollectionViewModel } from "lotAggregate/viewModels/LotCollectionViewModel";
import { LookupViewModel } from "infrastructure/lookup/LookupViewModel";
import {LotBulkCreateViewModel} from "../../lotAggregate/viewModels/LotBulkCreateViewModel";
import {
    SubdivisionCustomerInformationDetailsViewModel
} from "../viewModels/SubdivisionCustomerInformationDetailsViewModel";

export class SubdivisionGateway implements ISubdivisionGateway {
    private readonly _apiGateway: IAPIGateway;
    private readonly _baseUrl: string = "/api/subdivisions";

    constructor(apiGateway: IAPIGateway) {
        this._apiGateway = apiGateway;
    }

    public async addSubdivision(subdivisionEditViewModel: SubdivisionEditViewModel): Promise<number> {
        return await this._apiGateway.post(`${this._baseUrl}`, subdivisionEditViewModel)
    }

    public async addSubdivisionPlatMap(subdivisionId: number, platMap: File): Promise<number | void> {
        return await this._apiGateway.postFile(`${this._baseUrl}/${subdivisionId}/platMap`, platMap);
    }

    public async getSubdivisionLookupById(subdivisionId: number): Promise<SubdivisionLookupViewModel> {
        return await this._apiGateway.get(`${this._baseUrl}/${subdivisionId}/lookup`);
    }
    
    public async listLotTableDetailsById(subdivisionId: number, pageNumber: number = 0, sortViewModel?: SortViewModel): Promise<LotTableDetailViewModel[]> {
        return await this._apiGateway.post(`${this._baseUrl}/${subdivisionId}/lots/list?pageNumber=${pageNumber}`, sortViewModel ?? {});
    }

    public async updateSubdivision(subdivisionId: number, subdivisionEditViewModel: SubdivisionEditViewModel): Promise<void> {
        return await this._apiGateway.put(`${this._baseUrl}/${subdivisionId}`, subdivisionEditViewModel);
    }

    public async getSubdivisionBuildingDepartments(subdivisionId: number): Promise<BuildingDepartmentListViewModel[]> {
        return this._apiGateway.get(`${this._baseUrl}/${subdivisionId}/buildingDepartments`);
    }

    public async getSubdivisionCustomerDetails(subdivisionId: number): Promise<SubdivisionCustomerInformationDetailsViewModel[]> {
        return await this._apiGateway.get(`${this._baseUrl}/${subdivisionId}/subdivisionCustomerDetails`);
    }

    public async getSubdivisionDetails(subdivisionId: number): Promise<SubdivisionDetailsViewModel> {
        return await this._apiGateway.get(`${this._baseUrl}/${subdivisionId}/details`);
    }

    public async addLotToSubdivision(subdivisionId: number, lotEditViewModel: LotEditViewModel): Promise<number> {
        return this._apiGateway.post(`${this._baseUrl}/${subdivisionId}/lots`, lotEditViewModel)
    }
    
    public async getSubdivisionById(subdivisionId: number): Promise<SubdivisionEditViewModel> {
        return await this._apiGateway.get(`${this._baseUrl}/${subdivisionId}`);
    }

    public async getSubdivisionLotFormDetails(subdivisionId: number, lotId: number): Promise<LotEditViewModel> {
        return await this._apiGateway.get(`${this._baseUrl}/${subdivisionId}/lots/${lotId}/formDetails`);
    }

    public async updateSubdivisionLot(subdivisionId: number, lotId: number, lotEditViewModel: LotEditViewModel): Promise<void> {
        return await this._apiGateway.put(`${this._baseUrl}/${subdivisionId}/lots/${lotId}`, lotEditViewModel);
    }
    
    public async getSubdivisionPlatMap(subdivisionId: number): Promise<File | void> {
        return await this._apiGateway.getFile(`${this._baseUrl}/${subdivisionId}/platMap`);
    }

    public async deleteLotsBySubdivision(subdivisionId:number, lotBulkDeleteViewModel: LotBulkDeleteViewModel): Promise<void>{
        return await this._apiGateway.delete(`${this._baseUrl}/${subdivisionId}/lots`, lotBulkDeleteViewModel.lotIds);
    }
    
    public async updateLotsBySubdivision(subdivisionId: number,lotBulkEditViewModel:LotBulkEditViewModel): Promise<void>{
        return await this._apiGateway.put(`${this._baseUrl}/${subdivisionId}/lots`, lotBulkEditViewModel);
    }
    
    public async getLotRangeUpdateFormDetails(subdivisionId: number, lotIds: LotCollectionViewModel): Promise<LotEditViewModel> {
        return await this._apiGateway.post(`${this._baseUrl}/${subdivisionId}/lots/bulkFormDetails`, lotIds);
    }
    
    public async getSubdivisionLotLookup(subdivisionId:number, lotId:number): Promise<LookupViewModel> {
        return await this._apiGateway.get(`${this._baseUrl}/${subdivisionId}/lots/${lotId}/lookup`);
    }
    
    public async addMultipleLotsToSubdivision(subdivisionId: number, lotEditViewModel: LotBulkCreateViewModel): Promise<number[]> {
        return await this._apiGateway.post(`${this._baseUrl}/${subdivisionId}/bulkLots`, lotEditViewModel);
    }
    
    public async getSubdivisionLotsLookup(subdivisionId: number): Promise<LookupViewModel[]> {
        return await this._apiGateway.get(`${this._baseUrl}/${subdivisionId}/lots/lookup`);
    }
    
    public async listSubdivisionPlansLookup(subdivisionId: number): Promise<LookupViewModel[]> {
        return await this._apiGateway.get(`${this._baseUrl}/${subdivisionId}/plans/lookup`);
    }
}