import {PricingMatrixUnitType} from "../Domain/PricingMatrixUnitType";
import {OptionTypeViewModel} from "../../valueObjects/OptionTypeViewModel";

export const PricingMatrixDiscountDropdownOptions: OptionTypeViewModel[] = [
    {
        id: PricingMatrixUnitType.Amount,
        name: "Amount"
    },
    {
        id: PricingMatrixUnitType.Percentage,
        name: "Percentage"
    }
]