import {ICustomerGateway} from "../../customerAggregate/gateways/ICustomerGateway";
import {ISubdivisionGateway} from "../../subdivisionAggregate/gateway/ISubdivisionGateway";
import {IPlanGateway} from "../../planAggregate/gateways/IPlanGateway";
import {IOrderEntryBatchDomainService} from "./IOrderEntryBatchDomainService";
import {Lookup} from "../../globalComponents/lookup/Lookup";
import {GarageOrientationEnum} from "../../planAggregate/GarageOrientation";

export class OrderEntryBatchDomainService implements IOrderEntryBatchDomainService {
    private readonly _customerGateway: ICustomerGateway;
    private readonly _subdivisionGateway: ISubdivisionGateway;
    private readonly _planGateway: IPlanGateway;
    
    constructor(customerGateway: ICustomerGateway, subdivisionGateway: ISubdivisionGateway, planGateway: IPlanGateway) {
        this._customerGateway = customerGateway;
        this._subdivisionGateway = subdivisionGateway;
        this._planGateway = planGateway;
    }
    
    public async getSubdivisionOptions(isSubdivisionDisabled: boolean, customerId?: number) {
        if(isSubdivisionDisabled || !customerId) {
            throw Error(`Cannot retrieve Subdivisions options until Customer is selected.`);
        }
        return await this._customerGateway.ListSubdivisionLookupsByCustomerId(customerId, true);
    }
    
    public async getPlanOptions(isPlanDisabled: boolean, customerId?: number): Promise<Lookup[]> {
        console.log(customerId, "Customer Id")
        if(isPlanDisabled || !customerId) {
            throw Error(`Cannot retrieve Plans options until Subdivision is selected.`);
        }
        return await this._customerGateway.ListCustomerPlansLookup(customerId);
    }
    
    public async getLotOptions (isSubdivisionDisabled: boolean, subdivisionId?: number): Promise<Lookup[]> {
        if(isSubdivisionDisabled || !subdivisionId) {
            throw Error(`Cannot retrieve Lot options until Subdivision is selected.`);
        }
        
        return await this._subdivisionGateway.getSubdivisionLotsLookup(subdivisionId);
    }
    
    public async getPlansDefaultGarageOrientation(planId: number): Promise<GarageOrientationEnum> {
        return await this._planGateway.getPlanDefaultGarageOrientation(planId);
    }
}