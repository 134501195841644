import {CustomerCreateEditViewModel} from "./CustomerCreateEditViewModel";
import {ContactViewModel} from "../../valueObjects/Contact/viewModels/ContactViewModel";
import {AddressViewModel} from "../../valueObjects/Address/viewModels/AddressViewModel";
import {RequirementSetEditViewModel} from "../../requirementSetAggregate/viewModels/RequirementSetEditViewModel";
import {CustomerInfoViewModel} from "./CustomerInfoViewModel";
import {OptionTypeViewModel} from "../../valueObjects/OptionTypeViewModel";
import {
    DeliveryRequirementsViewModel
} from "../../valueObjects/DeliveryRequirements/viewModels/DeliveryRequirementsViewModel";

export type CustomerCreateEditDto = {
    name: string;
    marketTitle: string;
    pricingLevelId: number;
    pricingLevelName: string;
    regionId?: number;
    regionName: string;
    parentCustomerId?: number;
    parentCustomerName?: string;
    primaryContact: ContactViewModel;
    billingContact: ContactViewModel;
    shippingAddress: AddressViewModel;
    billingAddress: AddressViewModel;
    additionalAddresses: AddressViewModel[];
    externalCustomerId?: number;
    requirementSetEditViewModel: RequirementSetEditViewModel;
    customerDeliveryRequirementsEditViewModel: DeliveryRequirementsViewModel;
}

export class CustomerDtoMapper {

    static convertToCustomerDTO(customerViewModel: CustomerCreateEditViewModel) {
        const result = {
            name: customerViewModel.customerInfo.name,
            marketTitle: customerViewModel.customerInfo.marketTitle,
            pricingLevelId: customerViewModel.customerInfo.pricingLevels.id,
            pricingLevelName: customerViewModel.customerInfo.pricingLevels.name,
            externalCustomerId: -1,
            billingAddress: customerViewModel.billingAddress,
            billingContact: customerViewModel.billingContact,
            primaryContact: customerViewModel.primaryContact,
            regionId: customerViewModel.customerInfo.region.id,
            regionName: customerViewModel.customerInfo.region.name,
            shippingAddress: customerViewModel.shippingAddress,
            requirementSetEditViewModel: customerViewModel.requirementSetEditViewModel,
            additionalAddresses: customerViewModel.additionalAddresses,
            customerDeliveryRequirementsEditViewModel: new DeliveryRequirementsViewModel(
                customerViewModel.customerDeliveryRequirementsEditViewModel.electronicFileSetup,
                customerViewModel.customerDeliveryRequirementsEditViewModel.printRequirements,
                customerViewModel.customerDeliveryRequirementsEditViewModel.packingRequirements,
                customerViewModel.customerDeliveryRequirementsEditViewModel.printDeliveryMethod,
                customerViewModel.customerDeliveryRequirementsEditViewModel.customerAddressType,
                customerViewModel.customerDeliveryRequirementsEditViewModel.eReceipts
            ),
        } as CustomerCreateEditDto
        return result;
    }

    static convertToCustomerCreateViewModel(customerDto: CustomerCreateEditDto) {
        return new CustomerCreateEditViewModel(
            new CustomerInfoViewModel(
                customerDto.name,
                customerDto.marketTitle,
                new OptionTypeViewModel(
                    customerDto.regionId ?? 0,
                    customerDto.regionName
                ),
                new OptionTypeViewModel(
                    customerDto.pricingLevelId,
                    customerDto.pricingLevelName
                ),
            ),
            new ContactViewModel(
                customerDto.primaryContact.id,
                customerDto.primaryContact.firstName,
                customerDto.primaryContact.lastName,
                customerDto.primaryContact.contactRole,
                customerDto.primaryContact.email,
                customerDto.primaryContact.phone
            ),
            new ContactViewModel(
                customerDto.billingContact.id,
                customerDto.billingContact.firstName,
                customerDto.billingContact.lastName,
                customerDto.billingContact.contactRole,
                customerDto.billingContact.email,
                customerDto.billingContact.phone,
            ),
            new AddressViewModel(
                customerDto.shippingAddress.id,
                customerDto.shippingAddress.address1,
                customerDto.shippingAddress.city,
                customerDto.shippingAddress.state,
                customerDto.shippingAddress.zipCode,
                customerDto.shippingAddress.country,
                customerDto.shippingAddress.phone,
                customerDto.shippingAddress.address2,
            ),
            new AddressViewModel(
                customerDto.billingAddress.id,
                customerDto.billingAddress.address1,
                customerDto.billingAddress.city,
                customerDto.billingAddress.state,
                customerDto.billingAddress.zipCode,
                customerDto.billingAddress.country,
                customerDto.billingAddress.phone,
                customerDto.billingAddress.address2,
            ),
            customerDto.additionalAddresses?.map(x => {
                return new AddressViewModel(Number(x.id), x.address1, x.city, x.state, x.zipCode, x.country, x.phone, x.address2, x.displayName)
            }) ?? [],
            {
                id: customerDto?.requirementSetEditViewModel?.id,
                disciplinePhaseSetEditViewModels: customerDto?.requirementSetEditViewModel?.disciplinePhaseSetEditViewModels
            } as RequirementSetEditViewModel,
            new DeliveryRequirementsViewModel(
                customerDto.customerDeliveryRequirementsEditViewModel.electronicFileSetup,
                customerDto.customerDeliveryRequirementsEditViewModel.printRequirements,
                customerDto.customerDeliveryRequirementsEditViewModel.packingRequirements,
                customerDto.customerDeliveryRequirementsEditViewModel.printDeliveryMethod,
                customerDto.customerDeliveryRequirementsEditViewModel.customerAddressType,
                customerDto.customerDeliveryRequirementsEditViewModel.eReceipts,
            ),
        )
    }

}