import {ProjectTeam} from "../../projectTeamAggregate/domain/ProjectTeam";
import {PricingMatrix} from "../../pricingMatrixAggregate/Domain/PricingMatrix";
import {PricingMatrixUnitType} from "../../pricingMatrixAggregate/Domain/PricingMatrixUnitType";

export class DisciplinePhase {
    private _id: number;
    private _name: string;
    private _disciplineId: number;
    private _projectTeam?: ProjectTeam;
    private _pricingMatrix?: PricingMatrix;
    private _isSelected: boolean;
    

    constructor(id: number, name: string, disciplineId: number, isSelected: boolean, pricingMatrix?: PricingMatrix, projectTeam?: ProjectTeam) {
        this._id = id;
        this._name = name;
        this._disciplineId = disciplineId;
        this._pricingMatrix = pricingMatrix;
        this._isSelected = isSelected;
        if(projectTeam) {
            this._projectTeam = projectTeam;
        } else {
            this._projectTeam = new ProjectTeam(0, this._id, []);
        }
    }
    
    private clone(): DisciplinePhase {
        return new DisciplinePhase(this._id, this._name, this._disciplineId, this._isSelected, this._pricingMatrix, this._projectTeam);
    }
    
    public updateDisciplinePhaseProjectTeam(projectTeam: ProjectTeam): DisciplinePhase {
        this._projectTeam = projectTeam;
        return new DisciplinePhase(this._id, this._name, this._disciplineId, this._isSelected, this._pricingMatrix, this._projectTeam);
    }
    
    public setIsSelected(isSelected: boolean) {
        const result = this.clone();
        result._isSelected = isSelected;
        return result;
    }
    
    public updateDisciplinePhasePricingMatrix(pricingMatrix: PricingMatrix): DisciplinePhase {
        this._pricingMatrix = pricingMatrix;
        if (pricingMatrix.bonusDiscountType === PricingMatrixUnitType.Percentage && Number(pricingMatrix.bonusDiscount.value) > 100) {
            throw new Error("Percentage value for Bonus Discount cannot be greater than 100");
        }
        return new DisciplinePhase(this._id, this._name, this._disciplineId, this._isSelected, this._pricingMatrix, this._projectTeam)
    }


    get id(): number {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get disciplineId(): number {
        return this._disciplineId;
    }

    get projectTeam(): ProjectTeam | undefined {
        return this._projectTeam;
    }

    get pricingMatrix(): PricingMatrix | undefined {
        return this._pricingMatrix;
    }
    
    get isSelected(): boolean {
        return this._isSelected;
    }
}