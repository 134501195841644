export class SubdivisionCustomerInformationEditViewModel {
    public readonly customerId: number;
    public readonly trussManufacturerId: number;
    public readonly activePlanIds?: number[];
    public readonly communitySpecificInfo?: string;
    
    constructor(customerId: number, trussManufacturerId: number, communitySpecificInfo?: string, activePlanIds?: number[]) {
        this.customerId = customerId;
        this.trussManufacturerId = trussManufacturerId;
        this.communitySpecificInfo = communitySpecificInfo;
        this.activePlanIds = activePlanIds;
    }
}