export const VariancePurchaseOrderURLs = {
    vpoLogin: '/vpo/login',
    vpoView: '/vpo',
    internalEditView: '/vpo/:id/edit',
    confirmationView: '/vpo/:id/confirmation',
    internalConfirmationView: '/vpo/:id/internalConfirmation',
    finalizeView: '/vpo/:id/finalize',
    verifyView: '/vpo/verify',
    verifyEmailView: '/vpo/verifyEmail',
    magicLinksDecodeView: '/vpo/magicLinks/decode/:id'
}