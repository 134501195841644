import {SubdivisionBuildingDepartment} from "./subdivisionCustomer/SubdivisionBuildingDepartment";

export class SubdivisionAdditionalInformation {
    private _subdivisionBuildingDepartments?: SubdivisionBuildingDepartment[];
    private _dateAdded: string;
    private _subdivisionStatus?: string;
    private _subdivisionPhase?: string;
    private _platMap?: File;

    constructor(subdivisionBuildingDepartments: SubdivisionBuildingDepartment[] = [], dateAdded: string, subdivisionStatus?: string, subdivisionPhases?: string, platMap?: File) {
        this._subdivisionBuildingDepartments = subdivisionBuildingDepartments;
        this._dateAdded = dateAdded;
        this._subdivisionStatus = subdivisionStatus;
        this._subdivisionPhase = subdivisionPhases;
        this._platMap = platMap;
    }

    private clone() {
        return new SubdivisionAdditionalInformation(this._subdivisionBuildingDepartments, this._dateAdded, this._subdivisionStatus, this._subdivisionPhase, this._platMap);
    }


    public addSubdivisionBuildingDepartment(subdivisionBuildingDepartments: SubdivisionBuildingDepartment) {
        if(this._subdivisionBuildingDepartments?.some(x => x.id === subdivisionBuildingDepartments.id)) {
            throw new Error("Cannot add duplicate building departments")
        }
        this._subdivisionBuildingDepartments?.unshift(subdivisionBuildingDepartments);
        return this.clone();
    }
    
    public removeSubdivisionBuildingDepartment(id: number): SubdivisionAdditionalInformation {
        const index = this._subdivisionBuildingDepartments?.findIndex(x => x.id === id);
        if(index === -1) {
            throw new Error("Cannot find building department to remove");
        }
        this._subdivisionBuildingDepartments?.splice(index!, 1);
        return this.clone();
    }

    

    public updateSubdivisionStatus(subdivisionStatus: string) {
        this._subdivisionStatus = subdivisionStatus;
        return this.clone();
    }

    public updateSubdivisionPhases(subdivisionPhases: string) {
        this._subdivisionPhase = subdivisionPhases;
        return this.clone();
    }
    
    public updateDateAdded(dateAdded: string) {
        this._dateAdded = dateAdded;
        return this.clone();
    }

    public updatePlatMap(platMap: File): SubdivisionAdditionalInformation {
        const result = this.clone();
        result._platMap = platMap;
        return result;
    }
    
    //region getters
    public get subdivisionBuildingDepartments(){
        return this._subdivisionBuildingDepartments;
    }
    
    public get dateAdded(): string  {
        return this._dateAdded;
    }

    public get subdivisionStatus() {
        return this._subdivisionStatus;
    }

    public get subdivisionPhase() {
        return this._subdivisionPhase;
    }

    get platMap(): File | undefined {
        return this._platMap;
    }
    //endregion
}