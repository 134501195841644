import {ISkillSetRepo} from "./ISkillSetRepo";
import {ISkillSetGateway} from "../gateways/ISkillSetGateway";
import {SkillSet} from "../domain/SkillSet";
import {SkillSetViewModel} from "../viewModels/SkillSetViewModel";

export class SkillSetRepo implements ISkillSetRepo {
    private readonly _skillSetGateway: ISkillSetGateway;

    constructor(skillSetGateway: ISkillSetGateway) {
        this._skillSetGateway = skillSetGateway;
    }

    public async GetAllSkillSets(): Promise<Array<SkillSet>> {
        const result: SkillSetViewModel[] = await this._skillSetGateway.GetSkillSets();
        return result.map(x => {
            return new SkillSet(x.id, x.name)
        });
    }
}