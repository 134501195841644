import {Lookup} from "../../globalComponents/lookup/Lookup";

export enum JobStatus {
    New,
    InProgress
}

export const JobStatusOptions = [
    new Lookup(JobStatus.New, "New"),
    new Lookup(JobStatus.InProgress, "In Progress"),
]