import {IServiceContext} from "./IServiceContext";
import {APIGateway} from "./APIGateway";
import {SystemSettingsGateway} from "../systemSettingsAggregate/gateways/SystemSettingsGateway";
import {ComponentManufacturerGateway} from "../componentManufacturerAggregate/gateways/ComponentManufacturerGateway";
import OmniSearchGateway from "../omniSearchAggregate/gateways/OmniSearchGateway";
import {BuildingDepartmentGateway} from "../buildingDepartmentAggregate/gateway/BuildingDepartmentGateway";
import CustomerGateway from "../customerAggregate/gateways/CustomerGateway";
import VPOGateway from "../variancePurchaseOrderAggregate/gateways/VPOGateway";
import UserGateway from "../userAggregate/gateways/UserGateway";
import MagicLinkGateway from "../magicLinkAggregate/gateways/MagicLinkGateway";
import DeacomItemGateway from "../deacomItemAggregate/gateways/DeacomItemGateway";
import {TrussManufacturerGateway} from "../trussManufacturerAggregate/gateway/TrussManufacturerGateway";
import {RegionGateway} from "../regionAggregate/gateways/RegionGateway";
import {PricingLevelGateway} from "../pricingLevelAggregate/gateways/PricingLevelGateway";
import {PlanGateway} from "../planAggregate/gateways/PlanGateway";
import {PlanVersionGateway} from "../planVersionAggregate/gateways/PlanVersionGateway";
import RequirementSetGateway from "../requirementSetAggregate/gateways/RequirementSetGateway";
import {DisciplineGateway} from "../disciplineAggregate/gateways/DisciplineGateway";
import {SkillSetGateway} from "../skillSetAggregate/gateways/SkillSetGateway";
import {LookupGateway} from "./lookup/LookupGateway";
import {SubdivisionGateway} from "../subdivisionAggregate/gateway/SubdivisionGateway";
import {OrderEntryBatchGateway} from "../orderEntryAggregate/gateways/OrderEntryBatchGateway";
import {RequirementSetRepository} from "../requirementSetAggregate/repos/RequirementSetRepository";
import {DisciplineRepo} from "../disciplineAggregate/repos/DisciplineRepo";
import {UserService} from "../userAggregate/service/UserService";
import {SkillSetRepo} from "../skillSetAggregate/repos/SkillSetRepo";
import {BuildingDepartmentService} from "../buildingDepartmentAggregate/service/BuildingDepartmentService";
import {SubdivisionService} from "../subdivisionAggregate/service/SubdivisionService";
import {SubdivisionRepo} from "../subdivisionAggregate/repo/SubdivisionRepo";
import {CustomerService} from "../customerAggregate/service/CustomerService";
import {OrderEntryBatchDomainService} from "../orderEntryAggregate/service/OrderEntryBatchDomainService";
import {OrderEntryBatchFormRepository} from "../orderEntryAggregate/repository/OrderEntryBatchFormRepository";
import {OrderEntryBatchFormService} from "../orderEntryAggregate/service/OrderEntryBatchFormService";
import {RequirementSetService} from "../requirementSetAggregate/service/RequirementSetService";

/**
 * The goal long term is for the ServiceProvider to be somewhat of a messenger to the UI layer that these are all the available services the UI can interact with. Because this was implemented while Ignition was far along in development, legacy patterns are present, primarily the ability for the UI to interact directly with the gateway. For any new services created, no gateways should be provided through the service context, but only services.
 */
export class ServiceProvider {
    private static _instance: ServiceProvider;
    private readonly _serviceContext: IServiceContext;


    private constructor() {
        const apiGateway = new APIGateway();
        const systemSettingsGateway = new SystemSettingsGateway();
        const componentManufacturerGateway = new ComponentManufacturerGateway(apiGateway);
        const omniSearchGateway = new OmniSearchGateway(apiGateway);
        const buildingDepartmentGateway = new BuildingDepartmentGateway(apiGateway);
        const customerGateway = new CustomerGateway(apiGateway);
        const vpoGateway = new VPOGateway();
        const userGateway = new UserGateway(apiGateway);
        const magicLinkGateway = new MagicLinkGateway();
        const deacomItemGateway = new DeacomItemGateway();
        const trussManufacturerGateway = new TrussManufacturerGateway(apiGateway);
        const regionGateway = new RegionGateway(apiGateway);
        const pricingLevelGateway = new PricingLevelGateway(apiGateway);
        const planGateway = new PlanGateway(apiGateway);
        const planVersionGateway = new PlanVersionGateway(apiGateway);
        const requirementSetGateway = new RequirementSetGateway(apiGateway);
        const disciplineGateway = new DisciplineGateway(apiGateway);
        const skillSetGateWay = new SkillSetGateway(apiGateway);
        const lookupGateway = new LookupGateway(apiGateway);
        const subdivisionGateway = new SubdivisionGateway(apiGateway);
        const orderEntryBatchGateway = new OrderEntryBatchGateway(apiGateway);
        const orderEntryBatchRepository = new OrderEntryBatchFormRepository(orderEntryBatchGateway, customerGateway, planGateway, subdivisionGateway, componentManufacturerGateway);
        const requirementSetRepository = new RequirementSetRepository(requirementSetGateway, disciplineGateway);

        
        //TODO: Remove requirement set repository and gateway from service provider. This should be done before this branch is merged back to master
        this._serviceContext = {
            SystemSettingsGateway: systemSettingsGateway,
            OmniSearchGateway: omniSearchGateway,
            CustomerGateway: customerGateway,
            VPOGateway: vpoGateway,
            UserGateway: userGateway,
            BuildingDepartmentGateway: buildingDepartmentGateway,
            MagicLinkGateway: magicLinkGateway,
            DeacomItemGateway: deacomItemGateway,
            RegionGateway: regionGateway,
            PricingLevelGateway: pricingLevelGateway,
            ComponentManufacturerGateway: componentManufacturerGateway,
            TrussManufacturerGateway: trussManufacturerGateway,
            PlanVersionGateway: planVersionGateway,
            PlanGateway: planGateway,
            RequirementSetGateway: requirementSetGateway,
            DisciplineGateway: disciplineGateway,
            SkillSetGateWay: skillSetGateWay,
            LookupGateway: lookupGateway,
            SubdivisionGateway: subdivisionGateway,
            OrderEntryBatchGateway: orderEntryBatchGateway,
            RequirementSetRepo: requirementSetRepository,
            DisciplineRepo: new DisciplineRepo(disciplineGateway),
            UserService: new UserService(userGateway),
            SkillSetRepo: new SkillSetRepo(skillSetGateWay),
            BuildingDepartmentService: new BuildingDepartmentService(buildingDepartmentGateway, userGateway),
            SubdivisionService: new SubdivisionService(customerGateway, subdivisionGateway, trussManufacturerGateway, userGateway),
            SubdivisionRepo: new SubdivisionRepo(subdivisionGateway, customerGateway, trussManufacturerGateway),
            CustomerService: new CustomerService(customerGateway, userGateway),
            OrderEntryBatchService: new OrderEntryBatchFormService(orderEntryBatchRepository),
            OrderEntryBatchDomainService: new OrderEntryBatchDomainService(customerGateway, subdivisionGateway, planGateway),
            RequirementSetService: new RequirementSetService(requirementSetRepository)
        }
    }

    public static getInstance(): ServiceProvider {
        if (!ServiceProvider._instance) {
            ServiceProvider._instance = new ServiceProvider();
        }
        return ServiceProvider._instance;
    }

    public getServiceContext(): IServiceContext {
        return this._serviceContext;
    }
}