import {LookupViewModel} from "../../infrastructure/lookup/LookupViewModel";

export class SubdivisionCustomerInformationDetailsViewModel {
    public readonly customerId: number;
    public readonly customerName: string;
    public readonly trussManufacturerId: number;
    public readonly trussManufacturerName: string;
    public readonly communitySpecificInfo: string;
    public readonly activePlans: LookupViewModel[];
    
    constructor(customerId: number, customerName: string, trussManufacturerId: number, trussManufacturerName: string, communitySpecificInfo: string, activePlans: LookupViewModel[]) {
        this.customerId = customerId;
        this.customerName = customerName;
        this.trussManufacturerId = trussManufacturerId;
        this.trussManufacturerName = trussManufacturerName;
        this.communitySpecificInfo = communitySpecificInfo;
        this.activePlans = activePlans;
    }
}