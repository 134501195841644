import {TypeAheadField} from "../../globalComponents/typeAhead/TypeAheadField";
import {OrderEntryFormSpecification} from "../specification/OrderEntryFormSpecification";
import {OrderEntryStatus} from "./OrderEntryStatus";
import {
    GARAGE_ORIENTATION_TOOLTIP_DISABLED,
    LOT_TOOLTIP_DISABLED,
    PLAN_TOOLTIP_DISABLED,
} from "./OrderEntryTooltipMessages";
import {OrderEntryEmailAssociation} from "./OrderEntryEmailAssociation";
import {OrderEntryFormMode} from "./OrderEntryFormMode";
import {Lookup} from "../../globalComponents/lookup/Lookup";
import {garageOrientationOptions} from "../../planAggregate/GarageOrientation";

export class OrderEntryForm {
    private readonly _id: string;
    private _viewId?: string;
    private readonly _orderEntryBatchId: string;
    private _formMode: OrderEntryFormMode;
    private _orderEntryStatus: OrderEntryStatus;
    private _customerField: TypeAheadField;
    private _subdivisionField: TypeAheadField;
    private _planField: TypeAheadField;
    private _lotField: TypeAheadField;
    private _garageOrientationField: TypeAheadField;
    private _componentManufacturer: TypeAheadField;
    private _dueDate: Date;
    private _jobStatus: TypeAheadField;
    private _priority: TypeAheadField;
    private _trussesIn: Date;
    private _isOpen: boolean;
    private _specification: OrderEntryFormSpecification;
    private _orderEntryAssociation: OrderEntryEmailAssociation[];
    private _isActionMenuAvailable: boolean;

    constructor(id: string, orderEntryBatchId: string, orderEntryStatus: OrderEntryStatus, customerField: TypeAheadField, subdivisionField: TypeAheadField, planField: TypeAheadField, lotField: TypeAheadField, garageOrientationField: TypeAheadField, componentManufacturer: TypeAheadField, dueDate: Date, jobStatus: TypeAheadField, priority: TypeAheadField, trussesIn: Date, isOpen: boolean = false, viewId?: string, orderEntryAssociation: OrderEntryEmailAssociation[] = [], isActionMenuAvailable: boolean = false, orderEntryFormMode: OrderEntryFormMode = OrderEntryFormMode.Add) {
        this._id = id;
        this._orderEntryStatus = orderEntryStatus;
        this._orderEntryBatchId = orderEntryBatchId;
        this._customerField = customerField;
        this._subdivisionField = subdivisionField;
        this._planField = planField;
        this._lotField = lotField;
        this._garageOrientationField = garageOrientationField;
        this._componentManufacturer = componentManufacturer;
        this._dueDate = dueDate;
        this._jobStatus = jobStatus;
        this._priority = priority;
        this._trussesIn = trussesIn;
        this._isOpen = isOpen;
        this._specification = new OrderEntryFormSpecification();
        this._viewId = viewId;
        this._isActionMenuAvailable = isActionMenuAvailable;
        this._orderEntryAssociation = orderEntryAssociation;
        this._formMode = orderEntryFormMode;
    }

    private clone(): OrderEntryForm {
        return new OrderEntryForm(this._id, this._orderEntryBatchId, this._orderEntryStatus, this._customerField, this._subdivisionField, this._planField, this._lotField, this._garageOrientationField, this._componentManufacturer, this._dueDate, this._jobStatus, this._priority, this._trussesIn, this._isOpen, this._viewId, this._orderEntryAssociation, this._isActionMenuAvailable, this._formMode);
    }

    public canAddToBatch() {
        return this._specification.isSatisfiedBy(this).isSatisfied;
    }

    public hasAssociationWithEmail(orderEntryId: string, orderEmailId: string): boolean {
        return !!(this._id === orderEntryId && this._orderEntryAssociation.find(x => x.orderEntryEmailId === orderEmailId));
    }

    public doesCustomerHaveInheritedValues() {
        return !!this._subdivisionField.value;
    }

    public doesSubdivisionHaveInheritedValues() {
        return !!this._planField.value || !!this._lotField.value;
    }

    public doesPlanHaveInheritedValues() {
        return !!this._garageOrientationField.value;
    }

    //region state methods
    public updateCustomerTypeAheadValue(customerValue: Lookup): OrderEntryForm {
        const result = this.clone();
        result._customerField = new TypeAheadField(result._customerField.label, result._customerField.isDisabled, result._customerField.placeholderText, result._customerField.options, result._customerField.tooltipMessage, customerValue, result._customerField.hasInheritedFields, result._customerField.searchResult);

        if (result._subdivisionField.value) {
            result._customerField = result._customerField.openInheritedFieldAlert();
        }
        if (result._customerField.value) {
            result._subdivisionField = result._subdivisionField.enable().updateTooltipMessage("");
        }
        
        return result;
    }

    public resetCustomersInheritedFields() {
        const result = this.clone();
        result._subdivisionField = new TypeAheadField(result._subdivisionField.label, false, result._subdivisionField.placeholderText, [], "", undefined, result._subdivisionField.hasInheritedFields, result._subdivisionField.searchResult, result._subdivisionField.inheritedFieldChangeAlert);
        result._planField = new TypeAheadField(result._planField.label, true, result._planField.placeholderText, [], PLAN_TOOLTIP_DISABLED, undefined, result._planField.hasInheritedFields, result._planField.searchResult, result._planField.inheritedFieldChangeAlert);
        result._lotField = new TypeAheadField(result._lotField.label, true, result._lotField.placeholderText, [], LOT_TOOLTIP_DISABLED, undefined, result._lotField.hasInheritedFields, result._lotField.searchResult, result._lotField.inheritedFieldChangeAlert);
        result._garageOrientationField = new TypeAheadField(result._garageOrientationField.label, true, result._garageOrientationField.placeholderText, garageOrientationOptions, GARAGE_ORIENTATION_TOOLTIP_DISABLED, undefined, result._garageOrientationField.hasInheritedFields, result._garageOrientationField.searchResult, result._garageOrientationField.inheritedFieldChangeAlert);
        return result;
    }

    public resetSubdivisionsInheritedFields(): OrderEntryForm {
        const result = this.clone();
        result._planField = new TypeAheadField(result._planField.label, false, result._planField.placeholderText, [], "", undefined, result._planField.hasInheritedFields, result._planField.searchResult, result._planField.inheritedFieldChangeAlert);
        result._lotField = new TypeAheadField(result._lotField.label, false, result._lotField.placeholderText, [], "", undefined, result._lotField.hasInheritedFields, result._lotField.searchResult, result._lotField.inheritedFieldChangeAlert);
        result._garageOrientationField = new TypeAheadField(result._garageOrientationField.label, true, result._garageOrientationField.placeholderText, garageOrientationOptions, GARAGE_ORIENTATION_TOOLTIP_DISABLED, undefined, false, result._garageOrientationField.searchResult, result._garageOrientationField.inheritedFieldChangeAlert);
        return result;
    }

    public resetPlansInheritedFields(): OrderEntryForm {
        const result = this.clone();
        result._garageOrientationField = new TypeAheadField(result._garageOrientationField.label, false, result._garageOrientationField.placeholderText, garageOrientationOptions, GARAGE_ORIENTATION_TOOLTIP_DISABLED, undefined, result._garageOrientationField.hasInheritedFields, result._garageOrientationField.searchResult, result._garageOrientationField.inheritedFieldChangeAlert);
        return result;
    }

    public setIsOpen(isOpen: boolean) {
        const result = this.clone();
        result._isOpen = isOpen;
        return result;
    }

    public setOrderEntryStatus(status: OrderEntryStatus): OrderEntryForm {
        const result = this.clone();
        result._orderEntryStatus = status;
        return result;
    }

    public updateSubdivisionFieldOptions(options: Lookup[]) {
        const result = this.clone();
        result._subdivisionField = result._subdivisionField.updateOptions(options);
        return result;
    }

    public updatePlanTypeAheadOptions(options: Lookup[]) {
        const result = this.clone();
        result._planField = result._planField.updateOptions(options);
        return result;
    }

    public updateLotTypeAheadOptions(options: Lookup[]) {
        const result = this.clone();
        result._lotField = result._lotField.updateOptions(options);
        return result;
    }

    public setOrderEntryFormMode(formMode: OrderEntryFormMode): OrderEntryForm {
        const result = this.clone();
        result._formMode = formMode;
        return result;
    }

    public updateSubdivisionTypeAhead(subdivision: TypeAheadField): OrderEntryForm {
        const result = this.clone();
        result._subdivisionField = subdivision;
        if (result._subdivisionField.value) {
            result._planField = result._planField.enable().updateTooltipMessage("");
            result._lotField = result._lotField.enable().updateTooltipMessage("");
        }
        return result;
    }

    public updatePlanTypeAhead(plan: TypeAheadField): OrderEntryForm {
        const result = this.clone();
        result._planField = plan;
        if (result._planField.value) {
            result._garageOrientationField = result._garageOrientationField.enable();
        }
        return result;
    }
    
    public updatePlanTypeAheadValue(value: Lookup) {
        const result = this.clone();
        result._planField = result._planField.updateValue(value);
        return result;
    }

    public updateLotTypeAhead(lot: TypeAheadField): OrderEntryForm {
        const result = this.clone();
        result._lotField = lot;
        return result;
    }

    public updateGarageOrientationTypeAhead(garageOrientation: TypeAheadField): OrderEntryForm {
        const result = this.clone();
        result._garageOrientationField = garageOrientation;
        return result;
    }
    
    public updateGarageOrientationToolTipMessage(message: string) {
        const result = this.clone();
        result._garageOrientationField = result._garageOrientationField.updateTooltipMessage(message);
        return result;
    }

    public updateComponentManufacturerTypeAhead(componentManufacturer: TypeAheadField): OrderEntryForm {
        const result = this.clone();
        result._componentManufacturer = componentManufacturer;
        return result;
    }

    public updateDueDate(dueDate: Date): OrderEntryForm {
        const result = this.clone();
        result._dueDate = dueDate;
        return result;
    }

    public updateJobStatus(jobStatus: TypeAheadField): OrderEntryForm {
        const result = this.clone();
        result._jobStatus = jobStatus;
        return result;
    }

    public updatePriorityTypeAhead(priority: TypeAheadField): OrderEntryForm {
        const result = this.clone();
        result._priority = priority;
        return result;
    }

    public updateTrussesIn(trussesIn: Date): OrderEntryForm {
        const result = this.clone();
        result._trussesIn = trussesIn;
        return result;
    }

    //endregion


    //region setters

    set isActionMenuAvailable(isActionMenuAvailable: boolean) {
        this._isActionMenuAvailable = isActionMenuAvailable;
    }

    //endregion

    //region getters
    get id(): string {
        return this._id;
    }

    get specification(): OrderEntryFormSpecification {
        return this._specification;
    }

    get orderEntryStatus(): OrderEntryStatus {
        return this._orderEntryStatus;
    }

    get isActionMenuAvailable(): boolean {
        return this._isActionMenuAvailable;
    }

    get orderEntryBatchId(): string {
        return this._orderEntryBatchId;
    }

    get formMode(): OrderEntryFormMode {
        return this._formMode;
    }

    get isOpen(): boolean {
        return this._isOpen;
    }

    get customerField(): TypeAheadField {
        return this._customerField;
    }

    get subdivisionField(): TypeAheadField {
        return this._subdivisionField;
    }

    get viewId(): string | undefined {
        return this._viewId;
    }

    get planField(): TypeAheadField {
        return this._planField;
    }

    get lotField(): TypeAheadField {
        return this._lotField;
    }

    get garageOrientationField(): TypeAheadField {
        return this._garageOrientationField;
    }

    get componentManufacturer(): TypeAheadField {
        return this._componentManufacturer;
    }

    get dueDate(): Date {
        return this._dueDate;
    }

    get orderEntryAssociation(): OrderEntryEmailAssociation[] {
        return this._orderEntryAssociation;
    }

    get jobStatus(): TypeAheadField {
        return this._jobStatus;
    }

    get priority(): TypeAheadField {
        return this._priority;
    }

    get trussesIn(): Date {
        return this._trussesIn;
    }

//endregion
}