import {BuildingDepartmentForm} from "../domain/BuildingDepartmentForm";
import {IBuildingDepartmentGateway} from "../gateway/IBuildingDepartmentGateway";
import {BuildingDepartmentEditViewModel} from "../viewModels/BuildingDepartmentEditViewModel";
import {BuildingDepartmentDeliveryRequirements} from "../valueObjects/BuildingDepartmentDeliveryRequirements";
import {BuildingDepartmentLocation} from "../valueObjects/BuildingDepartmentLocation";
import {IBuildingDepartmentService} from "./IBuildingDepartmentService";
import {BuildingDepartmentLocationEditViewModel} from "../viewModels/BuildingDepartmentLocationEditViewModel";
import {
    BuildingDepartmentDeliveryRequirementsEditViewModel
} from "../viewModels/BuildingDepartmentDeliveryRequirementsEditViewModel";
import {BuildingDepartmentMode} from "../BuildingDepartmentMode";
import {BuildingDepartmentRow} from "../valueObjects/BuildingDepartmentRow";
import {IUserGateway} from "../../userAggregate/gateways/IUserGateway";
import {TableType} from "../../globalComponents/Table/TableType";
import {BuildingDepartmentTable} from "../valueObjects/BuildingDepartmentTable";
import {TableTitle} from "../../globalComponents/Table/TableTitle";
import {TableColumnArranger} from "../../globalComponents/Table/columnRearrange/TableColumnArranger";
import {Lookup} from "../../globalComponents/lookup/Lookup";

export class BuildingDepartmentService implements IBuildingDepartmentService {
    private readonly _buildingDepartmentGateway: IBuildingDepartmentGateway;
    private readonly _userGateway: IUserGateway;

    constructor(buildingDepartmentGateway: IBuildingDepartmentGateway, userGateway: IUserGateway) {
        this._buildingDepartmentGateway = buildingDepartmentGateway;
        this._userGateway = userGateway;
    }

    public addBuildingDepartment(buildingDepartmentForm: BuildingDepartmentForm): Promise<number> {
        const {state, city, county} = buildingDepartmentForm.buildingDepartmentLocation;
        const {electronicFileSetup, printRequirementSheetSize, packingRequirements, printDeliveryMethod, address, eReceipts, printRequirementNumberOfCopies} = buildingDepartmentForm.buildingDepartmentDeliveryRequirements;
        if(buildingDepartmentForm.name === "" || !buildingDepartmentForm.name) {
            throw new Error("Building Department Name was not provided.");
        }
        if (state === "" || !county || city === "") {
            throw new Error("Building Department state, county, or city was not provided.");
        }
        if (electronicFileSetup === "" || !printRequirementSheetSize) {
            throw new Error("Building Department Electronic File Setup and Sheet Size are required.");
        }
        
        const buildingDepartmentEditViewModel = new BuildingDepartmentEditViewModel(
            buildingDepartmentForm.id,
            buildingDepartmentForm.name,
            new BuildingDepartmentLocationEditViewModel(city, state, county),
            new BuildingDepartmentDeliveryRequirementsEditViewModel(electronicFileSetup, printRequirementSheetSize, printRequirementNumberOfCopies, packingRequirements, printDeliveryMethod, address, eReceipts)
        );
        return this._buildingDepartmentGateway.addBuildingDepartment(buildingDepartmentEditViewModel);
    }
    
    public async getBuildingDepartmentFormDetailsById(id: number): Promise<BuildingDepartmentForm> {
        const result = await this._buildingDepartmentGateway.getBuildingDepartmentFormDetailsById(id);
        return new BuildingDepartmentForm(
             id,
            false,
            false,
            BuildingDepartmentMode.Update,
            result.name, 
            new BuildingDepartmentLocation(
                result.buildingDepartmentLocationEditViewModel.city,
                result.buildingDepartmentLocationEditViewModel.state,
                result.buildingDepartmentLocationEditViewModel.county
            ),
            new BuildingDepartmentDeliveryRequirements(
                result.buildingDepartmentDeliveryRequirementsEditViewModel.electronicFileSetup,
                result.buildingDepartmentDeliveryRequirementsEditViewModel.printRequirementSheetSize,
                result.buildingDepartmentDeliveryRequirementsEditViewModel.printRequirementNumberOfCopies,
                result.buildingDepartmentDeliveryRequirementsEditViewModel.packingRequirements,
                result.buildingDepartmentDeliveryRequirementsEditViewModel.printDeliveryMethod,
                result.buildingDepartmentDeliveryRequirementsEditViewModel.address,
                result.buildingDepartmentDeliveryRequirementsEditViewModel.eReceipts
            )
        );
    }
    
    public store(id: number, form: BuildingDepartmentForm): Promise<number | void> {
        const {name, buildingDepartmentDeliveryRequirements, buildingDepartmentLocation} = form;
        const {electronicFileSetup, printRequirementSheetSize} = buildingDepartmentDeliveryRequirements;
        const {state, city, county} = buildingDepartmentLocation;
        if(name === "" || !name) {
            throw new Error("Building Department Name was not provided.");
        }
        //TODO: add address once BE is implemented
        if (state === "" || !county || city === "") {
            throw new Error("Building Department state, county, or city was not provided.");
        }
        if (electronicFileSetup === "" || !printRequirementSheetSize) {
            throw new Error("Building Department Electronic File Setup and Sheet Size are required.");
        }
        const buildingDepartmentEditVM = new BuildingDepartmentEditViewModel(
            form.id,
            form.name,
            new BuildingDepartmentLocationEditViewModel(form.buildingDepartmentLocation.city, form.buildingDepartmentLocation.state, form.buildingDepartmentLocation.county),
            new BuildingDepartmentDeliveryRequirementsEditViewModel(form.buildingDepartmentDeliveryRequirements.electronicFileSetup, form.buildingDepartmentDeliveryRequirements.printRequirementSheetSize, form.buildingDepartmentDeliveryRequirements.printRequirementNumberOfCopies, form.buildingDepartmentDeliveryRequirements.packingRequirements, form.buildingDepartmentDeliveryRequirements.printDeliveryMethod, form.buildingDepartmentDeliveryRequirements.address, form.buildingDepartmentDeliveryRequirements.eReceipts)
        );
        
        return this._buildingDepartmentGateway.updateBuildingDepartmentForm(id, buildingDepartmentEditVM);
    }
    
    public async getBuildingDepartmentTable(userId: number): Promise<BuildingDepartmentTable> {
        const result = await this._buildingDepartmentGateway.getAllBuildingDepartmentsForTable(); 
        const tableSettings = await this._userGateway.getUserTableSettings(userId, TableType.BuildingDepartments);
        const tableHeaders = [
            new TableTitle(0, "Building Department Name"),
            new TableTitle(1, "City"),
            new TableTitle(2, "County"),
            new TableTitle(3, "State"),
        ]
        
        const buildingDepartmentTableRows = result.map(x => {
            return new BuildingDepartmentRow(x.id, x.name, new Map<number, string>().set(1, x.city), new Map<number, string>().set(2, x.county), new Map<number, string>().set(3, x.state))
        });
        const arrangeableColumns = tableHeaders.slice(1).map(x => new Lookup(x.id, x.displayValue));
        const buildingDepartmentTable = new BuildingDepartmentTable(tableHeaders, buildingDepartmentTableRows, new TableColumnArranger(arrangeableColumns));
        if(buildingDepartmentTable.hasUserSettings(buildingDepartmentTable.buildingDepartmentTableTitles, tableSettings.tableHeaderIds)) {
            return buildingDepartmentTable.applyUserSettings(tableSettings.tableHeaderIds);
        } else {
            return buildingDepartmentTable;
        }
    }
}