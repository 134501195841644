import {Disclosure, Popover, Transition} from "@headlessui/react";
import {Fragment} from 'react'
import {
    Bars3Icon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import {CogIcon, UserGroupIcon, UserIcon} from "@heroicons/react/24/solid";
import primaryFlameLogo from "../assets/BeLitFlameLogoPrimary.png";
import {
    BuildingOfficeIcon,
    ClipboardIcon,
    HomeModernIcon,
    UserPlusIcon
} from "@heroicons/react/20/solid";
import {CustomerURLs} from "../routes/CustomerURLs";
import {SubdivisionURLs} from "../routes/SubdivisionURLs";
import {BuildingDepartmentURLs} from "../routes/BuildingDepartmentURLs";

export const MobileNav = () => {
    const navigation = [
        {
            name: "Onboarding",
            href: "",
            icon: ClipboardIcon,
            current: false,
            children: [
                {name: 'Customers', icon: <UserPlusIcon className="w-6 h-6 text-white"/>,  href:`${CustomerURLs.customerFormAddView}`, className: "group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium text-white hover:bg-secondary hover:text-white"},
                {name: "Subdivisions", icon: <HomeModernIcon className="w-6 h-6 text-white"/>, href:`${SubdivisionURLs.addView}`, className:"group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium text-white hover:bg-secondary hover:text-white"},
                {name: "Building Departments", icon: <BuildingOfficeIcon className="w-6 h-6 text-white"/>, href:`${BuildingDepartmentURLs.listView}`, className:"group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium text-white hover:bg-secondary hover:text-white"},
                // {name: "Lots", icon: <FontAwesomeIcon className="w-6 h-6 text-white mr-2" icon={faHome}/>, href: "/lots", className:"group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium text-white hover:bg-secondary hover:text-white"},
                // {name: "Plans", icon: <Square3Stack3DIcon className="w-6 h-6 text-white mr-2"/>, href: "/plans", className:"group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium text-white hover:bg-secondary hover:text-white"},
            ]
        },
        {
            name: 'Admin',
            icon: UserIcon,
            href: "",
            current: false,
            children: [
                {name: 'Users', icon: <UserGroupIcon className="w-6 h-6 text-white"/>,  href: '/users', className: "group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium text-white hover:bg-secondary hover:text-white"},
                {name: 'Settings', icon: <CogIcon className="w-6 h-6 text-white"/>,  href: '/settings', className: "group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium text-white hover:bg-secondary hover:text-white"},
            ],
        }
    ]

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }
    
    return (
        <Popover className="md:hidden h-16">
            <div className="flex items-center justify-start py-3 md:justify-start md:space-x-10">
                <div className="md:hidden">
                    <Popover.Button
                        className="p-2 inline-flex items-center justify-center rounded-md bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                        <span className="sr-only">Open menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                    </Popover.Button>
                </div>
            </div>

            <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel focus
                               className="absolute z-10 inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden">
                    <div
                        className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="px-5 pt-5 pb-6">
                            <div className="flex items-center justify-between">
                                <div className={"px-3"}>
                                    <img
                                        className="h-8 w-auto"
                                        src={primaryFlameLogo}
                                        alt="BeLit Primary Logo"
                                    />
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button
                                        className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="mt-6">
                                <nav className="grid grid-cols-1 gap-1">
                                    {navigation.map((item) => (
                                        !item.children ? (
                                            <div
                                                key={item.name}
                                                role="button"
                                                className="flex items-center rounded-lg p-3 hover:bg-gray-50"
                                            >
                                                <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-primary text-white">
                                                    <item.icon className="h-6 w-6" aria-hidden="true"/>
                                                </div>
                                                <div
                                                    className="ml-4 text-base font-medium text-gray-900">{item.name}
                                                </div>
                                            </div>
                                        )
                                        :
                                        (
                                            <Disclosure as="div" key={item.name} className="space-y-1 transition duration-500">
                                                {({open}) => (
                                                    <>
                                                        <Disclosure.Button className={'w-full flex items-center rounded-lg p-3 mb-1 hover:bg-gray-50'}>
                                                            <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-primary text-white">
                                                                <item.icon className="h-6 w-6" aria-hidden="true"/>
                                                            </div>
                                                            <div className="w-full flex justify-between transition duration-500">
                                                                <div className="ml-4 text-base font-medium text-gray-900">
                                                                    {item.name}
                                                                </div>
                                                                <svg
                                                                    className={classNames(
                                                                        open ? 'text-primary transition duration-500 rotate-90' : 'text-primary',
                                                                        'mr-2 h-5 w-5 flex-shrink-0 transform transition-colors transition duration-500 ease-in-out group-hover:text-gray-200'
                                                                    )}
                                                                    viewBox="0 0 20 20"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path d="M6 6L14 10L6 14V6Z" fill="currentColor"/>
                                                                </svg>
                                                            </div>
                                                        </Disclosure.Button>
                                                        <Disclosure.Panel className="space-y-1 transition duration-500">
                                                            {
                                                                item.children.map((subItem) => (
                                                                    <Disclosure.Button
                                                                        key={subItem.name}
                                                                        as="a"
                                                                        href={subItem.href}
                                                                        className={subItem.className + "transition duration-500"}
                                                                    >
                                                                        <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-primary text-white">
                                                                            {subItem.icon}
                                                                        </div>
                                                                        <div className="ml-4 text-base font-medium text-gray-900">
                                                                            {subItem.name}

                                                                        </div>
                                                                    </Disclosure.Button>
                                                                ))}
                                                        </Disclosure.Panel>
                                                    </>
                                                )}
                                            </Disclosure>
                                        )
                                    ))}
                                </nav>
                            </div>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}