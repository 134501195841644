import {ICloneable} from "../../../generics/Cloneable/ICloneable";

export class ContactViewModel implements ICloneable<ContactViewModel> {
    public id: number;
    public contactRole: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public phone?: string;


    constructor(id: number, contactRole: string, firstName: string, lastName: string, email: string, phone: string = "") {
        this.id = id;
        this.contactRole = contactRole;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.phone = phone;
    }
    public clone() {
        return new ContactViewModel(this.id, this.contactRole, this.firstName, this.lastName, this.email, this.phone);
    }
    
    //region state methods
    
    public updateContactRole(contactRole: string) {
        const result = this.clone();
        result.contactRole = contactRole;
        return result;
    }
    
    public updateFirstName(firstName: string) {
        const result = this.clone();
        result.firstName = firstName;
        return result;
    }
    
    public updateLastName(lastName: string){
        const result = this.clone();
        result.lastName = lastName;
        return result;
    }
    
    public updateEmail(email: string) {
        const result = this.clone();
        result.email = email;
        return result;
    }
    
    public updatePhone(phone: string){
        const result = this.clone();
        result.phone = phone;
        return result;
    }
    
    //endregion    
}