import {ISkillSetGateway} from "./ISkillSetGateway";
import {IAPIGateway} from "../../infrastructure/IAPIGateway";

export class SkillSetGateway implements ISkillSetGateway {
    private readonly _apiService: IAPIGateway;
    private baseUrl: string = "/api/skillSets";
    constructor(apiService: IAPIGateway) {
        this._apiService = apiService;
    }
    public async GetSkillSets(): Promise<[]> {
        return await this._apiService.get(`${this.baseUrl}/list`);
    }
    
}