export class OrderEntryEmailAssociation {
    private _orderEntryId: string;
    private _orderEntryEmailId: string;
    private _orderEntryViewId: string;
    private _displayText: string;
    private _isSelected: boolean;
    
    constructor(orderEntryId: string, orderEntryEmailId: string, orderEntryViewId: string = "", associatedOrdersText: string = "", isSelected: boolean = false) {
        this._orderEntryId = orderEntryId;
        this._orderEntryEmailId = orderEntryEmailId;
        this._orderEntryViewId = orderEntryViewId;
        this._displayText = associatedOrdersText;
        this._isSelected = isSelected;
    }
    
    private clone() {
        return new OrderEntryEmailAssociation(this._orderEntryId, this._orderEntryEmailId, this._orderEntryViewId, this._displayText, this._isSelected);
    }
    
    public setIsSelected(isSelected: boolean) {
        const result = this.clone();
        result._isSelected = isSelected;
        return result;
    }
    
    //region getters

    get orderEntryId(): string {
        return this._orderEntryId;
    }

    get displayText(): string {
        return this._displayText;
    }

    get orderEntryEmailId(): string {
        return this._orderEntryEmailId;
    }

    get orderEntryViewId(): string {
        return this._orderEntryViewId;
    }
    
    get isSelected(): boolean {
        return this._isSelected;
    }
    
    set isSelected(value: boolean) {
        this._isSelected = value;
    }
    
    //endregion
}