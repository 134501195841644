import React, {useContext} from "react";
import {UserCircleIcon} from "@heroicons/react/20/solid";
import {OmniSearchComponent} from "../../omniSearchAggregate/ui/OmniSearchComponent";
import {UserContext} from "../../infrastructure/UserContext";
import {MobileNav} from "../MobileNav";

export const HeaderNavbar = () => {
    const {isLoaded, user, userDetail, fullName} = useContext(UserContext);
    return (
        <div className={"flex justify-between  h-16 bg-white shadow-md items-center px-3 sticky top-0 z-50"}>
            <div className={"flex justify-start items-center w-full"}>
                {user.userType === 1 ? <></> : <MobileNav/>}
                {user.userType === 2 || user.userType === 0 ?
                    <div className={"mx-2 w-full md:w-1/2"}>
                        <OmniSearchComponent/>
                    </div>
                    :
                    <></>
                }    
            </div>
            <div>
                    <div
                        key={userDetail.primaryEmail}
                        className="flex items-center px-2 rounded-lg sm:px-3 py-3"
                    >
                    <div className="flex flex-shrink-0 items-center">
                        <UserCircleIcon className="h-11 w-11 bg-white text-darkPrimary" aria-label={"User Profile Image"}/>
                    </div>
                    <div className="sm:mx-2 md:max-w-[12rem]">
                        <div className="focus:outline-none">
                            <span aria-hidden="true" />
                            <p className="truncate hidden sm:block text-base font-sans font-medium text-gray-400" aria-label={"Full Name"}>{isLoaded ? fullName : ""}</p>
                            <p className="truncate hidden sm:block font-sans text-xs font-normal text-gray-400" aria-label={"User Email"}>{isLoaded ? userDetail.primaryEmail : ""}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}