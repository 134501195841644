import {TableTitle} from "../../globalComponents/Table/TableTitle";

export enum CustomerSubdivisionTableDataPropertyValues {
    County = "County",
    BuildingDepartment = "Building Department",
    ActivePlans = "Active Plans",
    NumberOfLots = "Number of Lots",
    LotCompletionPercentage = "Lot Completion %"
}

export class CustomerSubdivisionTableData {
    private _id: number;
    private _subdivisionName: string;
    private _county?: Map<number, string>;
    private _buildingDepartmentName?: Map<number, string>;
    private _activePlans?: Map<number, string[]>;
    private _numberOfLots?: Map<number, number>;
    private _lotCompletionPercentage?: Map<number, number>;
    
    constructor(id: number, subdivisionName: string, county: string = "", countyTitleId: number, buildingDepartmentName: string = "", buildingDepartmentTitleId: number, activePlans: string[] = [], activePlansTitleId: number, numberOfLots: number = 0, numberOfLotsTitleId: number, lotCompletionPercentage: number = 0, lotCompletionPercentageTitleId: number) {
        this._id = id;
        this._subdivisionName = subdivisionName;
        this._county = new Map<number, string>().set(countyTitleId, county);
        this._buildingDepartmentName = new Map<number, string>().set(buildingDepartmentTitleId, buildingDepartmentName);
        this._activePlans = new Map<number, string[]>().set(activePlansTitleId, activePlans);
        this._numberOfLots = new Map<number, number>().set(numberOfLotsTitleId, numberOfLots);
        this._lotCompletionPercentage = new Map<number, number>().set(lotCompletionPercentageTitleId, lotCompletionPercentage);
    }
    
    public getPropertyByTitleIdStrategy(title: TableTitle) {
        switch (title.displayValue) {
            case CustomerSubdivisionTableDataPropertyValues.County:
                return this.getCountyByTitleId(title.id) ?? "N/A";
            case CustomerSubdivisionTableDataPropertyValues.BuildingDepartment:
                return this.getBuildingDepartmentNameByTitleId(title.id) ?? "N/A";
            case CustomerSubdivisionTableDataPropertyValues.ActivePlans:
                return this.getActivePlansByTitleId(title.id)?.join(", ") ?? "N/A";
            case CustomerSubdivisionTableDataPropertyValues.NumberOfLots:
                return this.getNumberOfLotsByTitleId(title.id)?.toString() ?? "N/A";
            case CustomerSubdivisionTableDataPropertyValues.LotCompletionPercentage:
                return `${this.getLotCompletionPercentageByTitleId(title.id)}%`;
            default:
                return "N/A";
        }
    }    
    
    private getCountyByTitleId(countyTitleId: number) {
        return this._county?.get(countyTitleId);
    }

    private getBuildingDepartmentNameByTitleId(titleId: number) {
        return this._buildingDepartmentName?.get(titleId);
    }

    private getActivePlansByTitleId(titleId: number) {
        return this._activePlans?.get(titleId);
    }

    private getNumberOfLotsByTitleId(titleId: number) {
        return this._numberOfLots?.get(titleId);
    }

    private getLotCompletionPercentageByTitleId(titleId: number) {
        return this._lotCompletionPercentage?.get(titleId);
    }

    get id(): number {
        return this._id;
    }

    get subdivisionName(): string {
        return this._subdivisionName;
    }


    get county(): Map<number, string> | undefined {
        return this._county;
    }

    get buildingDepartmentName(): Map<number, string> | undefined {
        return this._buildingDepartmentName;
    }

    get activePlans(): Map<number, string[]> | undefined {
        return this._activePlans;
    }

    get numberOfLots(): Map<number, number> | undefined {
        return this._numberOfLots;
    }

    get lotCompletionPercentage(): Map<number, number> | undefined {
        return this._lotCompletionPercentage;
    }
}