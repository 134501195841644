import {Component, ErrorInfo, ReactNode} from "react";

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return (
                <main className="mx-auto w-full max-w-7xl px-6 py-24 sm:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8">
                    <div className="max-w-lg">
                        <p className="text-base font-semibold leading-8 text-primary">404</p>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                            Sorry, we couldn’t find the page you’re looking for.
                        </p>
                        <div className="mt-10">
                            <div role="button" className="text-sm font-semibold leading-7 text-primary">
                                <span aria-hidden="true">&larr;</span> Back to home
                            </div>
                        </div>
                    </div>
                    <div className="hidden lg:block">
                    </div>
                </main>
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;