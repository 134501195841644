import {PricingMatrixUnitType} from "../Domain/PricingMatrixUnitType";
import {OptionTypeViewModel} from "../../valueObjects/OptionTypeViewModel";

export const PricingMatrixBillingTypeDropdownOptions: OptionTypeViewModel[] = [
    {
        id: PricingMatrixUnitType.Price,
        name: "Price"
    },
    {
        id: PricingMatrixUnitType.Each,
        name: "Each"
    }
]