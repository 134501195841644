import {SubdivisionAdditionalInformationViewModel} from "./SubdivisionAdditionalInformationViewModel";
import {SubdivisionCustomerInformationEditViewModel} from "./SubdivisionCustomerInformationEditViewModel";

export class SubdivisionEditViewModel {
    public readonly id: number;
    public readonly customerId: number;
    public readonly name: string;
    public readonly subdivisionCustomerInformation: SubdivisionCustomerInformationEditViewModel[];
    public readonly windSpeed?: string;
    public readonly windExposure?: string;
    public readonly seismicCategory?: string;
    public readonly additionalInformation?: SubdivisionAdditionalInformationViewModel;


    constructor(id: number, customerId: number, name: string, customerInformation: SubdivisionCustomerInformationEditViewModel[], windSpeed?: string, windExposure?: string, seismicCategory?: string, additionalInformation?: SubdivisionAdditionalInformationViewModel) {
        this.id = id;
        this.customerId = customerId;
        this.name = name;
        this.subdivisionCustomerInformation = customerInformation;
        this.windSpeed = windSpeed;
        this.windExposure = windExposure;
        this.seismicCategory = seismicCategory;
        this.additionalInformation = additionalInformation;
    }
}