import {IRequirementSetRepository} from "./IRequirementSetRepository";
import {IRequirementSetGateway} from "../gateways/IRequirementSetGateway";
import {RequirementSet} from "../domain/RequirementSet";
import {ProjectTeam} from "../../projectTeamAggregate/domain/ProjectTeam";
import {PricingMatrixFactory} from "../../pricingMatrixAggregate/Factories/PricingMatrixFactory";
import {PricingMatrix} from "../../pricingMatrixAggregate/Domain/PricingMatrix";
import {IDisciplineGateway} from "../../disciplineAggregate/gateways/IDisciplineGateway";
import {DisciplineMapper} from "../../disciplineAggregate/utils/DisciplineMapper";
import {UserSkillSetProjectTeamLink} from "../../userSkillSetAggregate/domain/UserSkillSetProjectTeamLink";
import {Discipline} from "../../disciplineAggregate/domain/Discipline";
import {DisciplinePhase} from "../../disciplinePhaseAggregate/domain/DisciplinePhase";


export class RequirementSetRepository implements IRequirementSetRepository {
    private readonly _requirementSetGateway: IRequirementSetGateway;
    private readonly _disciplineGateway: IDisciplineGateway;

    constructor(requirementSetService: IRequirementSetGateway, disciplinesService: IDisciplineGateway) {
        this._requirementSetGateway = requirementSetService;
        this._disciplineGateway = disciplinesService;
    }

    public getRequirementSetByCustomerId = async (id: number) => {
        const data = await this._requirementSetGateway.getRequirementSetByCustomerId(+id);
        const disciplineDtos = await this._disciplineGateway.getDisciplinesList();
        const disciplinePhaseDtos = disciplineDtos.map(async (x) => {
            return await this._disciplineGateway.getDisciplinePhasesByDisciplineId(x.id);
        });
        
        const resolvedDisciplinePhaseDtos = await Promise.all(disciplinePhaseDtos);
        
        const disciplinePhases = resolvedDisciplinePhaseDtos.flatMap(x => {
            return x.map(x => new DisciplinePhase(x.id, x.name, x.disciplineId, false));
        });

        const disciplines = disciplineDtos.map(x => {
            return new Discipline(x.id, x.name, disciplinePhases, false);
        });
        // const disciplineMapper = new DisciplineMapper(this._disciplineGateway, this._requirementSetGateway);
        // const disciplineList = await disciplineMapper.mapDisciplinePhaseSetEditVMToDiscipline(data?.disciplinePhaseSetEditViewModels);

        return new RequirementSet(data?.id, disciplines);
    }

    public getRequirementSetByPlanId = async (id: number) => {
        const data = await this._requirementSetGateway.getRequirementSetByPlanId(+id);
        const disciplineMapper = new DisciplineMapper(this._disciplineGateway, this._requirementSetGateway);
        const disciplineList = await disciplineMapper.mapDisciplinePhaseSetEditVMToDiscipline(data?.disciplinePhaseSetEditViewModels);
        //We wait for the promise to finish and return an array of disciplines.
        const resolvedDisciplineList = await Promise.all(disciplineList);
        return new RequirementSet(data.id, resolvedDisciplineList);
    }

    public async getRequirementSetProjectTeamByProjectTeamId(projectTeamId: number) {
        const data = await this._requirementSetGateway.getRequirementSetProjectTeamByProjectTeamId(projectTeamId);
        const userSkillSetProjectTeamLinks = data.userSkillSetProjectTeamLinks.map((x: UserSkillSetProjectTeamLink) => {
            return new UserSkillSetProjectTeamLink(x.userId, x.skillSetId, x.userName);
        });
        return new ProjectTeam(data.id, projectTeamId, userSkillSetProjectTeamLinks);
    }

    public async getRequirementSetPricingMatrixByPricingMatrixId(pricingMatrixId: number): Promise<PricingMatrix> {
        const result = await this._requirementSetGateway.getRequirementSetPricingMatrixByPricingMatrixId(pricingMatrixId);
        const pricingMatrixFactory = new PricingMatrixFactory();
        return pricingMatrixFactory.createPricingMatrixFromEditViewModel(result, pricingMatrixId);
    }
    
    public async getEmptyRequirementSet(): Promise<RequirementSet> {
        const disciplineDtos = await this._disciplineGateway.getDisciplinesList();
        const disciplinePhaseDtos = disciplineDtos.map((x) => {
            return this._disciplineGateway.getDisciplinePhasesByDisciplineId(x.id);
        });
        
        const resolvedDisciplinePhaseDtos = await Promise.all(disciplinePhaseDtos);

        const disciplinePhases = resolvedDisciplinePhaseDtos.flatMap(x => {
            return x.map(x => new DisciplinePhase(x.id, x.name, x.disciplineId, false));
        });
        
        const disciplines = disciplineDtos.map(async (x) => {
            
            return new Discipline(x.id, x.name, disciplinePhases, false);
        });
        const awaitedDisciplines = await Promise.all(disciplines);
        
        return new RequirementSet(0, awaitedDisciplines);
    }
}