export const CustomerURLs = {
    customerListView: '/customers',
    customerView: '/customers/:id',
    planView: "/customers/:customerId/plans/:planId",
    planOptions: "/customers/:customerId/plans/:planId/options",
    planVersions: "/customers/:customerId/plans/:planId/versions",
    planRevisions: "/customers/:customerId/plans/:planId/revisions",
    customerFormAddView: "/customers/add",
    customerFormEditView: "/customers/:id/edit",
    planFormAddView: "/customers/:customerId/plans/add",
    planFormEditView: "/customers/:customerId/plans/:planId/edit",
}