export class BuildingDepartmentDeliveryRequirements {
    public electronicFileSetup: string;
    public printRequirementSheetSize: string;
    public printRequirementNumberOfCopies: number;
    public packingRequirements: string;
    public printDeliveryMethod: string;
    public address: string;
    public eReceipts: number;


    constructor(electronicFileSetup: string, printRequirementSheetSize: string, printRequirementNumberOfCopies: number, packingRequirements: string, printDeliveryMethod: string, address: string, eReceipts: number) {
        this.electronicFileSetup = electronicFileSetup;
        this.printRequirementSheetSize = printRequirementSheetSize;
        this.printRequirementNumberOfCopies = printRequirementNumberOfCopies;
        this.packingRequirements = packingRequirements;
        this.printDeliveryMethod = printDeliveryMethod;
        this.address = address;
        this.eReceipts = eReceipts;
    }
}