import {createPortal} from "react-dom";

interface ILoadingWithBackdropProps {
    isLoading: boolean;
}


export const Loading = ({isLoading}: ILoadingWithBackdropProps) => {
    return (
        <>
            {isLoading &&
            createPortal(
                <div className={`bg-gray-300 opacity-30 fixed w-full h-full z-50 top-0 left-0 right-0 bottom-0`}>
                    <div className={`loadingIndicator fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50`}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>,
                document.body
            )
            }
        </>
    )
}