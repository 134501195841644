import {OrderEntryBatchForm} from "../domain/OrderEntryBatchForm";
import {OrderEntryForm} from "../domain/OrderEntryForm";
import {OrderEntryEmail} from "../domain/OrderEntryEmail";

//This is a builder... kind of...
export class OrderEntryBatchFormBuilder extends OrderEntryBatchForm {
    constructor(orderEntryBatchForm: OrderEntryBatchForm) {
        super(orderEntryBatchForm.batchId, orderEntryBatchForm.orderEntryForms, orderEntryBatchForm.batchEmails, orderEntryBatchForm.batchStatus);
    }

    public addOrderEntryForms(orderEntries: OrderEntryForm[]) {
        this._orderEntryForms = orderEntries;
    }

    public addBatchEmails(batchEmails: OrderEntryEmail[]) {
        this._batchEmails = batchEmails;
        this._batchEmails.forEach(x => x.setActionsMenu(false)
        );
    }
}