import {IUserGateway} from "./IUserGateway";
import {UserEditViewModel} from "../viewModels/UserEditViewModel";
import {IAPIGateway} from "../../infrastructure/IAPIGateway";
import {UserTableSettingsViewModel} from "../viewModels/UserTableSettingsViewModel";
import {TableType} from "../../globalComponents/Table/TableType";
import {UserListViewModel} from "../viewModels/UserListViewModel";
import {CustomerDetailViewModel} from "../../customerAggregate/viewModels/CustomerDetailViewModel";
import {CurrentUserViewModel} from "../viewModels/CurrentUserViewModel";

export default class UserGateway implements IUserGateway {
    baseUrl: string = '/api/users/';
    private readonly _apiGateway: IAPIGateway;
    
     constructor(apiGateway: IAPIGateway) {
         this._apiGateway = apiGateway;
     }

    public async getUsersList(page: number, search: string): Promise<UserListViewModel[]> {
        return await this._apiGateway.get(`${this.baseUrl}list?page=${page}&search=${search}`);
    }
    
    public async getUser (id: number): Promise<UserEditViewModel> {
        return await this._apiGateway.get(`${this.baseUrl}${id}`)
    }
    public async updateUser (id: number, data: UserEditViewModel, signal: AbortSignal) {
        return await this._apiGateway.put(`${this.baseUrl}${id}`, data, signal);
    }
    
    public async addUser(vm: UserEditViewModel): Promise<void> {
        return await this._apiGateway.post(`${this.baseUrl}add`, vm);
    }
    
    public async removeUser(id: number, signal: AbortSignal) {
        return await this._apiGateway.delete(`${this.baseUrl}${id}`, signal);
    }
    
    public async getUserInfo(): Promise<CurrentUserViewModel> {
        return await this._apiGateway.get(`${this.baseUrl}getUserInformation`);
    }
    
    public async getUserCustomer(userId: number): Promise<CustomerDetailViewModel> {
        return await this._apiGateway.get(`${this.baseUrl}${userId}/customer`);
    }
    
    public async getUserTableSettings(userId: number, tableType: TableType): Promise<UserTableSettingsViewModel> {
        return this._apiGateway.get(`${this.baseUrl}${userId}/${tableType}/settings`);
    }
    
    public async updateUserTableSettings(userId: number, userTableSettingsViewModel: UserTableSettingsViewModel): Promise<void> {
        return this._apiGateway.put(`${this.baseUrl}${userId}/${userTableSettingsViewModel.tableType}/settings`, userTableSettingsViewModel)
    }
    
    public async listUsers(pageNumber: number = 0, search: string = ""): Promise<UserListViewModel[]> {
         return this._apiGateway.get<UserListViewModel[]>(`${this.baseUrl}list?page=${pageNumber}&search=${search}`);
    }
}