import {IMappedTheme, ITheme} from "./types";
import {themes} from "./useTheme";

export const mapTheme: (variables: ITheme) => IMappedTheme = (variables: ITheme) => {
    const mappedColors: {[key: string]: string} = {}

    for(const color in variables.colors) {
        mappedColors[`--color-${color}`] = variables.colors[color];
    }
    return mappedColors
};

/**
 * Helper function to set a new theme
 *
 * @param {string} theme The name of the theme to be set
 *
 * @return {void}
 */
export const applyTheme = (theme: string): void => {
    const themeObject: IMappedTheme = mapTheme(themes[theme]);
    if (!themeObject) return;
    const root = document.documentElement;

    Object.keys(themeObject).forEach((property) => {
        if (property === 'name') {
            return;
        }

        root.style.setProperty(property, themeObject[property]);
    });
    
    if (typeof window === "object") {
        const faviconTag = document.querySelector("[rel='icon']")
        if (faviconTag) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            faviconTag.href = themes[theme].logos.favicon
        }
        document.title = themes[theme].name
    }

};