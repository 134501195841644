import {Dispatch, ReactNode, SetStateAction} from "react";
import {Combobox} from "@headlessui/react";
import {OmniSearchInputComponent} from "./OmniSearchInputComponent";
import {OmniSearchOptionsComponent} from "./OmniSearchOptionsComponent";
import {OmniSearchValueObject} from "../valueObjects/OmniSearchValueObject";

interface IOmniSearchTypeaheadProps {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    listInnerRef: any,
    options: OmniSearchValueObject[],
    selectedCategory: string,
    setSelectedCategory: Dispatch<SetStateAction<string>>,
    isLoaded: boolean,
    icon?: ReactNode,
    disabled: boolean,
    dataTestId: string,
    placeholderText: string,
    query: string,
    setQuery: Dispatch<SetStateAction<string>>,
}

export const OmniSearchTypeaheadComponent = ({
                                                 listInnerRef,
                                                 selectedCategory,
                                                 setSelectedCategory,
                                                 setQuery,
                                                 options,
                                                 isLoaded,
                                                 icon,
                                                 disabled,
                                                 dataTestId,
                                                 placeholderText,
                                                 query,
                                             }: IOmniSearchTypeaheadProps) => {
    return (
        <Combobox as="div" value={selectedCategory}
                  onChange={setSelectedCategory} disabled={disabled}
                  aria-labelledby="combobox" data-testid={dataTestId}>
            <div className="relative">
                <OmniSearchInputComponent setQuery={setQuery}  icon={icon} placeholderText={placeholderText}/>
                <OmniSearchOptionsComponent isLoaded={isLoaded} query={query}
                                            options={options} listInnerRef={listInnerRef}/>
            </div>
        </Combobox>
    )
}