export class UserSkillSetProjectTeamLink {
    userId: number;
    skillSetId: number;
    userName?: string;

    constructor(userId: number, skillSetId: number, userName?: string) {
        this.userId = userId;
        this.skillSetId = skillSetId;
        this.userName = userName;
    }
}