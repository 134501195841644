import {UserSkillSetProjectTeamLink} from "../../userSkillSetAggregate/domain/UserSkillSetProjectTeamLink";


export class ProjectTeam {
    public id: number;
    public disciplinePhaseId: number;
    public userSkillSetProjectTeamLinks: Array<UserSkillSetProjectTeamLink>;
    
    constructor(id: number, disciplinePhaseId: number, userSkillSetProjectTeamLink: Array<UserSkillSetProjectTeamLink>) {
        this.id = id;
        this.disciplinePhaseId = disciplinePhaseId;
        this.userSkillSetProjectTeamLinks = userSkillSetProjectTeamLink;
    }
    
    public AddUserSkillSetProjectTeamLink(userSkillSetProjectTeamLink: UserSkillSetProjectTeamLink){
        //If we are just replacing/updating a userSkillSetProjectTeamLink, we want to replace what was there, not add to the list 
        const index = this.userSkillSetProjectTeamLinks.findIndex(x => x.skillSetId === userSkillSetProjectTeamLink.skillSetId)
        if (index !== -1) {
            this.userSkillSetProjectTeamLinks.splice(index, 1, userSkillSetProjectTeamLink);
        } else {
            this.userSkillSetProjectTeamLinks.push(userSkillSetProjectTeamLink);
        }
        return new ProjectTeam(this.id, this.disciplinePhaseId, this.userSkillSetProjectTeamLinks);
    }
}