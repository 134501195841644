import {LookupValues} from "./LookupValues";
import {ILookupGateway} from "./ILookupGateway";
import {IAPIGateway} from "../IAPIGateway";
import {OptionTypeViewModel} from "../../valueObjects/OptionTypeViewModel";

export class LookupGateway implements ILookupGateway {
    private readonly _apiGateway: IAPIGateway;
    private readonly _baseUrl: string = "/api/lookup";
    
    constructor(apiGateway: IAPIGateway) {
        this._apiGateway = apiGateway;
    }

    public async listOptions(lookupValues?: LookupValues): Promise<OptionTypeViewModel[]> {
        const result: string[] = await this._apiGateway.get(`${this._baseUrl}/${lookupValues}`)

        return result?.map((x, index) => {
            return new OptionTypeViewModel(index, x);
        });
    }
}