import {OptionTypeViewModel} from "../../valueObjects/OptionTypeViewModel";
import {ICloneable} from "../../generics/Cloneable/ICloneable";
export class CustomerInfoViewModel implements ICloneable<CustomerInfoViewModel> {
    public name: string;
    public marketTitle: string;
    public region: OptionTypeViewModel;
    public pricingLevels: OptionTypeViewModel;


    constructor(name: string, marketTitle: string, region: OptionTypeViewModel, pricingLevels: OptionTypeViewModel) {
        this.name = name;
        this.marketTitle = marketTitle;
        this.region = region;
        this.pricingLevels = pricingLevels;
    }
    
    public clone() {
        return new CustomerInfoViewModel(this.name, this.marketTitle, this.region, this.pricingLevels);
    }
    
    public updateName(name: string) {
        const result = this.clone();
        result.name = name;
        return result;
    }
    
    public updateMarketTitle(marketTitle: string) {
        const result = this.clone();
        result.marketTitle = marketTitle;
        return result;
    }
    
    public updateRegion(region: OptionTypeViewModel) {
        const result = this.clone();
        result.region = region;
        return result;
    }
    
    public updatePricingLevels(pricingLevels: OptionTypeViewModel) {
        const result = this.clone();
        result.pricingLevels = pricingLevels;
        return result;
    }
}