export class Percentage {
    private _value: number;
    
    constructor(value: number = 0) {
        if(value < 0 || value > 100) {
            throw new Error("Percentages can only range from 0 to 100.");
        }
        this._value = value;
    }
    
    private clone() {
        return new Percentage(this._value);
    }
    
    public increaseBy(value: number): Percentage {
        const result = this.clone();
        const operation = result._value += value;
        if(operation > 100) {
            throw new Error("Percentages can only range from 0 to 100.");
        }
        return result;
    }
    
    public increaseTo100Percent(): Percentage {
        const result = this.clone();
        result._value = 100;
        return result;
    }


    get value(): string {
        return `${this._value}%`;
    }

    public getRawValue(): number {
        return this._value;
    }
}