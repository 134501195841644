import {ICustomerGateway} from "./ICustomerGateway";
import {CustomerCreateEditDto, CustomerDtoMapper} from "../viewModels/CustomerDto";
import {CustomerCreateEditViewModel} from "../viewModels/CustomerCreateEditViewModel";
import {IAPIGateway} from "../../infrastructure/IAPIGateway";
import {CustomerPlansLookupViewModel} from "../viewModels/CustomerPlansLookupViewModel";
import {CustomerSubdivisionListViewModel} from "../viewModels/CustomerSubdivisionListViewModel";
import {SubdivisionLookupViewModel} from "../../subdivisionAggregate/viewModels/SubdivisionLookupViewModel";
import {CustomerLookupViewModel} from "../viewModels/CustomerLookupViewModel";
import {CustomerListViewModel} from "../viewModels/CustomerListViewModel";
import {SubdivisionViewModel} from "../../variancePurchaseOrderAggregate/viewModels/SubdivisionViewModel";
import {CustomerViewModel} from "../viewModels/CustomerViewModel";
import {PlanListViewModel} from "../../planAggregate/viewModels/PlanListViewModel";

export default class CustomerGateway implements ICustomerGateway {
    baseUrl: string = '/api/customers/';
    private readonly _apiGateway: IAPIGateway;

    constructor(apiService: IAPIGateway) {
        this._apiGateway = apiService;
    }

    public async GetCustomerLookupList(signal: AbortSignal, search?: string): Promise<CustomerLookupViewModel> {
        return await this._apiGateway.get<CustomerLookupViewModel>(this.baseUrl + `customerLookup?search=${search}`, signal);
    }

    GetCustomerList = async (hasSubdivision?: boolean, signal?: AbortSignal): Promise<CustomerListViewModel[]> => {
        const queryParams = typeof hasSubdivision === "boolean" ? `hasSubdivision=${hasSubdivision}` : "";
        return await this._apiGateway.get(this.baseUrl + `listWithSubdivisions?${queryParams}`, signal);
    }

    ListSubdivisionsByCustomerForShippingLocation = async (customerId: number, search: string, signal: AbortSignal): Promise<SubdivisionViewModel[]> => {
        return await this._apiGateway.get(this.baseUrl + `${customerId}/lookup?search=${search}`, signal)
    }

    public async ListSubdivisionLookupsByCustomerId(customerId: number, hasLots?: boolean): Promise<SubdivisionLookupViewModel[]> {
        const queryParams = typeof hasLots === "boolean" ? `hasLots=${hasLots}` : "";
        return await this._apiGateway.get(`${this.baseUrl}${customerId}/lookup?${queryParams}`);
    }

    GetSubdivisions = async (signal: AbortSignal): Promise<SubdivisionViewModel> => {
        return await this._apiGateway.get(this.baseUrl + `list`, signal);
    }

    GetCustomerDetail = async (id: number, signal?: AbortSignal): Promise<CustomerViewModel> => {
        return await this._apiGateway.get(this.baseUrl + `${id}/view`, signal)
    }

    GetCustomerFormDetails = async (id: number): Promise<CustomerCreateEditViewModel> => {
        const response = await this._apiGateway.get<CustomerCreateEditDto>(this.baseUrl + `${id}/details`);
        return CustomerDtoMapper.convertToCustomerCreateViewModel(response);
    }

    GetCustomerLookup = async (id: number, signal?: AbortSignal): Promise<CustomerLookupViewModel> => {
        return await this._apiGateway.get(this.baseUrl + `${id}`, signal)
    }

    AddCustomer = async (customerCreateVm: CustomerCreateEditViewModel, signal: AbortSignal): Promise<void> => {
        return await this._apiGateway.post(this.baseUrl, CustomerDtoMapper.convertToCustomerDTO(customerCreateVm), signal);
    }

    ListCustomerPlans = async (customerId: number, signal?: AbortSignal): Promise<PlanListViewModel[]> => {
        return await this._apiGateway.get(this.baseUrl + `${customerId}/listPlans`, signal)
    }

    UpdateCustomer = async (customerId: number, customerCreateEditViewModel: CustomerCreateEditViewModel, signal: AbortSignal) => {
        return await this._apiGateway.put(this.baseUrl + `${customerId}`, CustomerDtoMapper.convertToCustomerDTO(customerCreateEditViewModel), signal);
    }

    ListCustomerPlansLookup = async (customerId: number): Promise<CustomerPlansLookupViewModel[]> => {
        return await this._apiGateway.get(`${this.baseUrl}${customerId}/listPlansLookup`)
    }

    public async ListCustomerSubdivisions(customerId: number, pageNumber: number = 0): Promise<CustomerSubdivisionListViewModel> {
        return this._apiGateway.get(`${this.baseUrl}${customerId}/subdivisions/list?pageNumber=${pageNumber}`);
    }
}