export class DateFormatter {
    public static formatDateStringToDate(dateString: string) {
        const date = new Date(dateString);
        let year;
        let month;
        let day;
        if (isNaN(date.getTime())) {
            // Invalid date, return original value
            throw new Error("Date is in invalid format. Please write it as mm/dd/yyyy");
        }
        //When using the date type as an input in the input box component, it sends the date back in this format once the user edits it - yyyy-mm-dd
        if (dateString.includes("-")) {
            const dateParts = dateString.split('-');
            year = parseInt(dateParts[0], 10); // Month is zero-based in Date constructor
            day = parseInt(dateParts[2], 10);
            month = parseInt(dateParts[1], 10) - 1;
        }
        //Initially the date is formatted using slashes, so we're handling the case in which it has slashes as separators here.
        else if(dateString.includes("/")){
            const dateParts = dateString.split('/');
            year = parseInt(dateParts[2], 10); // Month is zero-based in Date constructor
            day = parseInt(dateParts[1], 10);
            month = parseInt(dateParts[0], 10) - 1;

        }
        
        return new Date(year, month, day)
    
    }

    public static formatDateObjectToDateString(date: Date) {
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (adding 1 since it is zero-based)
        const day = String(date.getDate()).padStart(2, '0'); // Get the day
        const year = String(date.getFullYear()); // Get the year
        
        // Using template literal
        return `${month}/${day}/${year}`;
    }
}