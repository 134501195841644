import {
    SubdivisionCustomerActivePlansListViewModel
} from "../../viewModels/SubdivisionCustomerActivePlansListViewModel";
import {Lookup} from "../../../globalComponents/lookup/Lookup";

export class SubdivisionCustomerLink {
    private _id: number;
    private _customerId: number;
    private _customerName: string;
    private _trussManufacturerId: number;
    private _trussManufacturerName: string;
    private _activePlans: SubdivisionCustomerActivePlansListViewModel[];
    private _communitySpecificInfo?: string;
    
    constructor(id: number, customerId: number, customerName: string, trussManufacturerId: number, trussManufacturerName: string, activePlans: SubdivisionCustomerActivePlansListViewModel[], communitySpecificInfo?: string) {
        this._id = id;
        this._customerId = customerId;
        this._customerName = customerName;
        this._trussManufacturerId = trussManufacturerId;
        this._trussManufacturerName = trussManufacturerName;
        this._activePlans = activePlans;
        this._communitySpecificInfo = communitySpecificInfo;
    }
    
    public get customerId(){
        return this._customerId;
    }
    
    public get customerName(){
        return this._customerName;
    }
    
    public get trussManufacturerId(){
        return this._trussManufacturerId;
    }
    
    public get trussManufacturerName(){
        return this._trussManufacturerName;
    }
    
    public get activePlans(){
        return this._activePlans;
    }
    
    public get id(){
        return this._id;
    }
    
    public get communitySpecificInfo() {
        return this._communitySpecificInfo;
    }
    
    public updateId(id: number): SubdivisionCustomerLink {
        this._id = id;
        return this.clone();
    }
    
    public addActivePlan(activePlan: Lookup): SubdivisionCustomerLink {
        this._activePlans.unshift(activePlan);
        return this.clone();
    }
    
    public removeActivePlan(activePlanId: number): SubdivisionCustomerLink {
        const index = this._activePlans.findIndex(x => x.id === activePlanId);
        if (index === -1) {
            throw new Error("While trying to find the active plan to remove, was unable to find the right plan to remove from the subdivision customer link.");
        }
        
        this._activePlans.splice(index, 1);
        return this.clone();
    }
    
    public removeAllActivePlans(): SubdivisionCustomerLink{
        this._activePlans.pop();
        return this.clone();
    }
    
    public updateCustomer(customerId: number, customerName: string): SubdivisionCustomerLink {
        this._customerId = customerId;
        this._customerName = customerName;
        return this.clone();
    }
    
    public updateTrussManufacturer(trussManufacturerId: number, trussManufacturerName: string): SubdivisionCustomerLink {
        this._trussManufacturerId = trussManufacturerId;
        this._trussManufacturerName = trussManufacturerName;
        return this.clone();
    }
    public updateCommunitySpecificInfo(communitySpecificInfo: string): SubdivisionCustomerLink {
        this._communitySpecificInfo = communitySpecificInfo;
        return this.clone();
    }
    
    private clone() {
        return new SubdivisionCustomerLink(this.id, this._customerId, this._customerName, this._trussManufacturerId, this._trussManufacturerName, this._activePlans, this._communitySpecificInfo);
    }
}