import {Disclosure} from '@headlessui/react'
import {UserIcon, UserGroupIcon, CogIcon} from '@heroicons/react/24/solid'
import {useContext} from "react";
import {ClipboardIcon, HomeModernIcon, UserPlusIcon, BuildingOfficeIcon} from "@heroicons/react/20/solid";
import {ThemeContext} from "../infrastructure/ThemeContext";
import {CustomerURLs} from "../routes/CustomerURLs";
import {SubdivisionURLs} from "../routes/SubdivisionURLs";
import {BuildingDepartmentURLs} from "../routes/BuildingDepartmentURLs";
import {SettingsURLs} from "../routes/SettingsURLs";
import {UserURLs} from "../routes/UserURLs";
import {OrderEntryURLS} from "../routes/OrderEntryURLS";

const navigation = [
    {
     name: "Ordering",
        href: "",
        icon: ClipboardIcon,
        current: false,
        children: [
            {name: "New SS Reissue", href:`${OrderEntryURLS.newSsReissueView}`, className: "group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium text-white hover:bg-secondary hover:text-white" }
        ]
    },
     {
         name: "Onboarding",
         href: "",
         icon: ClipboardIcon,
         current: false,
         children: [
             {name: 'Customers', icon: <UserPlusIcon className="w-6 h-6 text-white mr-2"/>,  href:`${CustomerURLs.customerFormAddView}`, className: "group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium text-white hover:bg-secondary hover:text-white"},
             {name: "Subdivisions", icon: <HomeModernIcon className="w-6 h-6 text-white mr-2"/>, href: `${SubdivisionURLs.addView}`, className:"group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium text-white hover:bg-secondary hover:text-white"},
             {name: "Building Departments", icon: <BuildingOfficeIcon className="w-6 h-6 text-white mr-2"/>, href:`${BuildingDepartmentURLs.listView}`, className:"group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium text-white hover:bg-secondary hover:text-white"}
             // {name: "Lots", icon: <FontAwesomeIcon className="w-6 h-6 text-white mr-2" icon={faHome}/>, href: "/lots", className:"group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium text-white hover:bg-secondary hover:text-white"},
             // {name: "Plans", icon: <Square3Stack3DIcon className="w-6 h-6 text-white mr-2"/>, href: "/plans", className:"group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium text-white hover:bg-secondary hover:text-white"},
         ]
    },
    {
        name: 'Admin',
        icon: UserIcon,
        href: "",
        current: false,
        children: [
            {name: 'Users', icon: <UserGroupIcon className="w-6 h-6 text-white mr-2"/>,  href:`${UserURLs.listView}`, className: "group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium text-white hover:bg-secondary hover:text-white"},
            {name: 'Settings', icon: <CogIcon className="w-6 h-6 text-white mr-2"/>,  href:`${SettingsURLs.settingsView}`, className: "group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium text-white hover:bg-secondary hover:text-white"},
        ],
    }
]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

interface IVerticalNavbarProps {
    dataTestId: string;
}

export const VerticalNavbar = ({dataTestId}: IVerticalNavbarProps) => {
    const {secondaryLogo, themeName} = useContext(ThemeContext)
    return (
        <div data-testid={dataTestId} className="w-0 md:w-[263px] h-screen mx-0 flex flex-col overflow-y-auto border-r border-gray-200 bg-primary sticky top-0 z-40">
            <div className="flex flex-shrink-0 justify-center items-center px-4 bg-secondary h-16">
                <img className="h-8 w-auto" src={secondaryLogo} alt={`${themeName} Secondary Logo`}/>
            </div>
            <div className="mt-5 flex flex-grow flex-col">
                <nav className="flex-1 space-y-1 transition duration-500 h-full" aria-label="Sidebar">
                    {navigation.map((item) =>
                        !item.children ? (
                            <div key={item.name}>
                                <a
                                    href={item.href}
                                    className={classNames(
                                        item.current
                                            ? 'text-white transition duration-500 hover:bg-secondary hover:text-white'
                                            : 'text-white hover:bg-secondary hover:text-white',
                                        'group w-full flex items-center pl-7 pr-2 py-2 text-sm font-medium'
                                    )}
                                >
                                    <item.icon
                                        className={classNames(
                                            item.current ? "w-6 h-6 mr-2" : 'text-gray-400 group-hover:text-gray-500',
                                            'mr-3 flex-shrink-0 h-6 w-6'
                                        )}
                                        aria-hidden="true"
                                    />
                                    {item.name}
                                </a>
                            </div>
                        ) : (
                            <Disclosure as="div" key={item.name} className="space-y-1 transition duration-500">
                                {({open}) => (
                                    <>
                                        <Disclosure.Button
                                            className={classNames(
                                                item.current
                                                    ? 'text-white' : 'text-white hover:bg-secondary transition duration-500 hover:text-white',
                                                'group w-full flex items-center pl-7 py-2 text-left text-sm font-medium'
                                            )}
                                        >
                                            <item.icon className="w-6 h-6 mr-2" aria-hidden="true"/>
                                            <div className="w-full flex justify-between transition duration-500">
                                                {item.name}
                                                <svg
                                                    className={classNames(
                                                        open ? 'text-white transition duration-500 rotate-90' : 'text-white',
                                                        'mr-2 h-5 w-5 flex-shrink-0 transform transition-colors duration-500 ease-in-out group-hover:text-gray-200'
                                                    )}
                                                    viewBox="0 0 20 20"
                                                    aria-hidden="true"
                                                >
                                                    <path d="M6 6L14 10L6 14V6Z" fill="currentColor"/>
                                                </svg>
                                            </div>
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="space-y-1 transition duration-500">
                                            {
                                                item.children.map((subItem) => (
                                                    <Disclosure.Button
                                                        key={subItem.name}
                                                        as="a"
                                                        href={subItem.href}
                                                        className={subItem.className + "transition duration-500"}
                                                    >
                                                        {subItem.icon}
                                                        {subItem.name}
                                                    </Disclosure.Button>
                                                ))}
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        )
                    )}
                </nav>
            </div>
        </div>
    )
}