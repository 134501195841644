export class InheritedFieldChangeAlert<T> {
    private _isOpen: boolean;
    private _message: string;
    private _fieldToBeChanged: T;
    
    constructor(isOpen: boolean = false, fieldToBeChanged: T) {
        this._isOpen = isOpen;
        this._message = "By changing this field, any fields that inherit values based on this field will be reset and need to be re-entered.";
        this._fieldToBeChanged = fieldToBeChanged;
    }
    
    private clone() {
        return new InheritedFieldChangeAlert(this._isOpen, this._fieldToBeChanged);
    }
    
    //region state methods
    public setIsAlertOpen(isAlertOpen: boolean) {
        const result = this.clone();
        result._isOpen = isAlertOpen;
        return result;
    }
    
    public setValueToBeChanged(value: T) {
        const result = this.clone();
        result._fieldToBeChanged = value;
        return result;
    }
    
    //endregion
    
    get isOpen(): boolean {
        return this._isOpen;
    }

    get message(): string {
        return this._message;
    }
    
    get fieldToBeChanged(): T {
        return this._fieldToBeChanged;
    }
}